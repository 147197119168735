import PostGroup from '../PostGroup';

const PostList = ({ postGroups }) => {
  return (
    <>
      {postGroups.map((group, idx) => {
        const {
          groupDate,
          groupTitle,
          groupCount,
          groupTipTotal,
          groupPosts,
          shortlist
        } = group;

        return (
          <PostGroup
            key={idx}
            groupDate={groupDate}
            groupTitle={groupTitle}
            groupCount={groupCount}
            groupTipTotal={groupTipTotal}
            groupPosts={groupPosts}
            shortlist={shortlist}
          />
        );
      })}
    </>
  );
};

export default PostList;
