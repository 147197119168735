import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Icon, Image, Popup } from 'semantic-ui-react';

import HeaderMenu from '../HeaderMenu';
import VerifiedIcon from '../VerifiedIcon';
import Notifications from '../Notifications';

import { checkIsPostingCooldownActive } from '../../util/user';

const HeaderUser = () => {
  const isPostingDisabled = useSelector(
    state => state.settings.postingDisabled
  );
  const username = useSelector(state => state.user.currentUser.username);
  const userRole = useSelector(state => state.user.currentUser.userRole);
  const isVerified = useSelector(state => state.user.currentUser.verified);
  const displayName = useSelector(state => state.user.currentUser.displayName);
  const avatar = useSelector(state => state.user.currentUser.avatar);
  const lastPostTime = useSelector(
    state => state.user.currentUser.lastPostTime
  );

  const { isCooldownActive, timeRemaining } =
    checkIsPostingCooldownActive(lastPostTime);

  const postBtnDisabled = isPostingDisabled && userRole !== 'admin';

  const logoImgSrc =
    window.innerWidth < 501
      ? 'https://res.cloudinary.com/dwlkh8tj3/image/upload/assets/img/gfam-logo-only.png'
      : 'https://res.cloudinary.com/dwlkh8tj3/image/upload/assets/img/gfam-logo-teal-trans.png';

  return (
    <nav className='header__container'>
      <div className='header__wrapper'>
        <div className='header__user'>
          <Image src={avatar} circular alt='' />
          <div className='header__name-wrapper'>
            <div className='header__displayName'>
              {displayName ? displayName : username}

              <VerifiedIcon
                isVerified={isVerified}
                viewerIsUser={true}
                iconSize={'small'}
                isVerifiedClass={'verified-primary'}
                notVerifiedClass={'verified-grey'}
              />
            </div>
            <div className='header__username'>@{username}</div>
          </div>
        </div>
        <div className='header__section'>
          <Notifications />
          {postBtnDisabled ? (
            <Popup
              header={`Posting Disabled`}
              content={`Posting has been temporarily disabled`}
              trigger={
                <div className='header__plus-icon'>
                  <Icon name='plus' circular />
                </div>
              }
              inverted
            />
          ) : isCooldownActive ? (
            <Popup
              header={`Posting Cooldown Active`}
              content={`Only 1 post per day is allowed. You can post again ${timeRemaining}`}
              trigger={
                <div className='header__plus-icon'>
                  <Icon name='plus' circular />
                </div>
              }
              inverted
            />
          ) : (
            <Link to='/posts/new' className='header__plus-icon'>
              <Icon name='plus' circular />
            </Link>
          )}

          <HeaderMenu />

          <Link to='/' className='header__logo'>
            <Image src={logoImgSrc} />
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default HeaderUser;
