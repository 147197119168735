import {
  ADD_LESSONS,
  DELETE_LESSON_BY_ID,
  SET_HASHTAGS,
  SET_LESSONS,
  SET_LESSONS_LOADING,
  SET_PARAMS,
  SET_TOTAL
} from './types';

export const addLessons = lessons => ({
  type: ADD_LESSONS,
  payload: lessons
});

export const deleteLessonById = id => ({
  type: DELETE_LESSON_BY_ID,
  payload: id
});

export const setLessonHashtags = tags => ({
  type: SET_HASHTAGS,
  payload: tags
});

export const setLessons = lessons => ({
  type: SET_LESSONS,
  payload: lessons
});

export const setLessonsLoading = status => ({
  type: SET_LESSONS_LOADING,
  payload: status
});

export const setParams = params => ({
  type: SET_PARAMS,
  payload: params
});

export const setTotal = total => ({
  type: SET_TOTAL,
  payload: total
});
