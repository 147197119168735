import axios from 'axios';

import { alertOperations } from '../alert';
import { defaultErrorMessage } from '../alert/constants';
import { userOperations } from '../user';
import {
  addLessons,
  deleteLessonById,
  setLessons,
  setLessonHashtags,
  setLessonsLoading,
  setParams,
  setTotal
} from './actions';

// import { mergeLessons } from '../../util/lessons';

const { createAlert } = alertOperations;
const { setUser } = userOperations;

// Get all lesson hashtags
export const getLessonHashtags = () => async dispatch => {
  try {
    const { data } = await axios.get('/api/lesson-tags');
    const hashtags = data.hashtags.sort(function (a, b) {
      return b.count - a.count;
    });

    await dispatch(setLessonHashtags(hashtags));
  } catch (error) {
    console.log(error);
    dispatch(createAlert(error.message, 'negative'));
  }
};

// Get lessons by hashtag
export const getLessonsByHashtag = tag => async dispatch => {
  dispatch(setLessonsLoading(true));

  try {
    const { data } = await axios.get(`/api/lessons?tag=${tag}`);

    await dispatch(setLessons(data.lessons));
  } catch (error) {
    console.log(error);

    dispatch(setLessonsLoading(false));

    dispatch(createAlert(error.message, 'negative'));
  }
};

// Get lessons
export const getLessons = () => async (dispatch, getState) => {
  const { lessons } = getState();
  const { isLoading } = lessons;
  if (isLoading) return;

  dispatch(setLessonsLoading(true));

  try {
    const { data } = await axios.get(`/api/lessons`);

    await dispatch(setLessons(data.lessons));

    return { success: true };
  } catch (error) {
    console.log(error);
    dispatch(setLessonsLoading(false));
    dispatch(
      createAlert(
        'Oops, something went wrong - please try again later',
        'negative'
      )
    );

    return { success: false };
  }
};

// Get lessons
export const getList = () => async (dispatch, getState) => {
  try {
    const {
      lessons: { isLoading, params }
    } = getState();
    if (isLoading || params.fetched) return;

    dispatch(setLessonsLoading(true));

    const reqParams = {
      _skip: (params.page - 1) * params.perPage,
      _limit: params.perPage,
      _sort: params.sort,
      _order: params.order
    };

    // TODO: refactor to add tag and/or q params
    if (params.tag && params.tag !== 'all') {
      reqParams.tag = params.tag;
    }

    if (params.search) {
      reqParams.q = params.search;
    }

    const res = await axios.get(`/api/lessons`, { params: reqParams });

    if (params.page === 1) {
      // console.log('setting lessons');
      await dispatch(setLessons(res.data));
    } else {
      // console.log('adding lessons');
      await dispatch(addLessons(res.data));
    }

    await dispatch(setTotal(res.headers['x-total-count']));
    await dispatch(setLessonsLoading(false));

    return { success: true };
  } catch (error) {
    console.log(error);
    dispatch(setLessonsLoading(false));
    dispatch(
      createAlert(
        'Oops, something went wrong - please try again later',
        'negative'
      )
    );

    return { success: false };
  }
};

// update params and get list
export const updateParamsAndGetList = data => async dispatch => {
  try {
    await dispatch(setListParams(data));

    const result = await dispatch(getList());
    console.log(result);
  } catch (error) {
    console.log(error);
  }
};

// Delete lesson
export const deleteLesson = lessonId => async dispatch => {
  dispatch(setLessonsLoading(true));

  try {
    // delete lesson from database
    await axios.delete(`/api/lessons/${lessonId}`);

    // delete lesson data from loaded lessons
    await dispatch(deleteLessonById(lessonId));

    await dispatch(setUser());

    await dispatch(createAlert('Lesson removed', 'success'));

    return true;
  } catch (error) {
    console.log(error);

    dispatch(setLessonsLoading(false));
    dispatch(createAlert(defaultErrorMessage, 'negative'));

    return false;
  }
};

export const setListParams = data => async (dispatch, getState) => {
  try {
    const {
      lessons: { params }
    } = getState();

    const newParams = { ...params, ...data };

    await dispatch(setParams(newParams));

    return { success: true };
  } catch (error) {
    console.log(error);
    dispatch(createAlert(defaultErrorMessage, 'negative'));

    return { success: false };
  }
};
