import {
  ADD_CHALLENGE,
  DELETE_CHALLENGE_BY_ID,
  SET_CHALLENGES,
  SET_CHALLENGES_LOADING
} from './types';

export const addChallenge = challenge => ({
  type: ADD_CHALLENGE,
  payload: challenge
});

export const deleteChallengeById = id => ({
  type: DELETE_CHALLENGE_BY_ID,
  payload: id
});

export const setChallenges = challenges => ({
  type: SET_CHALLENGES,
  payload: challenges
});

export const setChallengesLoading = status => ({
  type: SET_CHALLENGES_LOADING,
  payload: status
});
