import {
  SET_POSTING_DISABLED,
  SET_ADS_ENABLED,
  SET_APP_SETTINGS
} from './types';

export const settingsInitialState = {
  postingDisabled: false,
  adsEnabled: false
};

const reducer = (state = settingsInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_POSTING_DISABLED:
      return {
        ...state,
        postingDisabled: payload
      };

    case SET_ADS_ENABLED:
      return {
        ...state,
        adsEnabled: payload
      };

    case SET_APP_SETTINGS:
      return {
        ...state,
        postingDisabled: payload.postingDisabled,
        adsEnabled: payload.adsEnabled
      };

    default:
      return state;
  }
};

export default reducer;
