import axios from 'axios';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { alertOperations } from '../alert';
import { defaultErrorMessage } from '../alert/constants';
import { userOperations } from '../user';
import {
  setImageFile,
  setImageURL,
  setPreviewImage,
  setTitle,
  setDescription,
  setContent,
  setTags,
  setIsGated,
  setGateFee,
  setUploading,
  setUploadSuccess,
  clearFormState,
  loadFormState
} from './actions';

import cloudinary from '../../services/cloudinary';

const { createAlert } = alertOperations;
const { setUser } = userOperations;

// set lesson form image file
export const setLessonImage = file => dispatch => {
  if (!file) {
    dispatch(
      createAlert(
        'Sorry, your image file is over 5MG or an unsupported type.',
        'negative'
      )
    );

    return;
  }

  try {
    const reader = new FileReader();
    reader.onload = function (e) {
      dispatch(setPreviewImage(_.uniqueId(), e.target.result));
    };

    reader.readAsDataURL(file);

    dispatch(setImageFile(file));
  } catch (error) {
    console.error(error);
    dispatch(
      createAlert(
        'Sorry, something went wrong. Please check your image file.',
        'negative'
      )
    );
  }
};

export const setLessonTitle = title => dispatch => {
  dispatch(setTitle(title));
};

export const setLessonDescription = text => dispatch => {
  dispatch(setDescription(text));
};

export const setLessonContent = content => dispatch => {
  dispatch(setContent(content));
};

export const setLessonTags = tags => dispatch => {
  const tagsArray = tags.split(' ');

  dispatch(setTags(tagsArray));
};

export const setLessonIsGated = status => dispatch => {
  dispatch(setIsGated(status));
};

export const setLessonGateFee = fee => dispatch => {
  dispatch(setGateFee(fee));
};

export const uploadPhoto =
  ({ userId, imageFile }) =>
  async (dispatch, getState) => {
    const { uploading } = getState().lessonForm;
    if (!userId || !imageFile || uploading) {
      return;
    }

    await dispatch(setUploading(true));

    const publicId = `${userId}/${uuidv4()}`;

    const instance = axios.create();
    delete instance.defaults.headers.common['x-auth-token'];

    const requestData = {
      file: imageFile,
      upload_preset: cloudinary.config.upload_preset,
      public_id: publicId
    };

    try {
      const res = await instance.post(
        cloudinary.config.upload_url,
        requestData
      );
      const { secure_url, public_id } = res.data;

      await dispatch(setImageURL(secure_url));

      await dispatch(createLesson(public_id));
    } catch (error) {
      dispatch(
        createAlert(
          'Image upload failed - Please check your image file',
          'negative'
        )
      );
      await dispatch(setUploading(false));
    }
  };

export const createLesson = photoPublicId => async (dispatch, getState) => {
  const { lessonForm } = getState();

  const { title, description, content, tags, isTipGated, tipGateFee } =
    lessonForm;

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const lessonData = {
    title,
    description,
    content,
    tags,
    isTipGated,
    tipGateFee,
    photoPublicId
  };

  const body = JSON.stringify({ lessonData });

  try {
    const res = await axios.post('/api/lessons', body, config);
    console.log('create lesson result: ', res.data);
    await dispatch(setUser());

    await dispatch(createAlert('Lesson created', 'positive'));
    await dispatch(setUploadSuccess(res.data.lesson._id));
  } catch (error) {
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }
  }
};

export const updateLesson = () => async (dispatch, getState) => {
  const { lessonForm } = getState();

  const {
    lessonId,
    title,
    description,
    content,
    tags,
    isTipGated,
    tipGateFee
  } = lessonForm;

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const lessonData = {
    title,
    description,
    content,
    tags,
    isTipGated,
    tipGateFee
  };

  const body = JSON.stringify({ lessonData });

  try {
    await axios.put(`/api/lessons/${lessonId}`, body, config);

    await dispatch(setUser());

    await dispatch(createAlert('Lesson updated', 'positive'));
    await dispatch(setUploadSuccess(lessonId));
  } catch (error) {
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }
  }
};

export const clearLessonForm = () => dispatch => dispatch(clearFormState());

export const loadLessonData = lessonData => dispatch =>
  dispatch(loadFormState(lessonData));
