import {
  SET_POSTING_DISABLED,
  SET_ADS_ENABLED,
  SET_APP_SETTINGS
} from './types';

export const setPostingDisabled = isDisabled => ({
  type: SET_POSTING_DISABLED,
  payload: isDisabled
});

export const setAdsEnabled = isEnabled => ({
  type: SET_ADS_ENABLED,
  payload: isEnabled
});

export const setAppSettings = settings => ({
  type: SET_APP_SETTINGS,
  payload: settings
});
