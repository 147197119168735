import {
  ADD_MESSAGE,
  ADD_MESSAGES,
  SET_MESSAGES,
  CLEAR_MESSAGES,
  SET_USERS
} from './types';

export const addMessage = message => ({
  type: ADD_MESSAGE,
  payload: message
});

export const addMessages = messages => ({
  type: ADD_MESSAGES,
  payload: messages
});

export const setMessages = messages => ({
  type: SET_MESSAGES,
  payload: messages
});

export const clearMessages = () => ({
  type: CLEAR_MESSAGES
});

export const setUsers = users => ({
  type: SET_USERS,
  payload: users
});
