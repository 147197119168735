export const filterOptions = [
  { key: 'none', text: 'None', value: 'none' },
  { key: 'following', text: 'Following', value: 'following' },
  { key: 'followers', text: 'Followers', value: 'followers' }
];

export function filterPostGroupsWithUserIds(postGroups, userIds) {
  if (!userIds || userIds.length === 0) {
    return postGroups;
  }

  const grpsWithFilteredGroupPosts = postGroups.map(group => {
    const { groupPosts } = group;

    const filteredGroupPosts = groupPosts.filter(post => {
      return userIds.includes(post.user);
    });

    return { ...group, groupPosts: filteredGroupPosts };
  });

  // filter out groups with no posts
  const filteredGroups = grpsWithFilteredGroupPosts.filter(group => {
    return group.groupPosts.length > 0;
  });

  return filteredGroups;
}
