import { SET_BLOG_DATA, SET_BLOG_LOADING, SET_BLOG_POST_DATA } from './types';

export const setBlogData = ({ items, skip, total }) => ({
  type: SET_BLOG_DATA,
  payload: {
    items,
    skip,
    total
  }
});

export const setBlogLoading = bool => ({
  type: SET_BLOG_LOADING,
  payload: bool
});

export const setBlogPostData = data => ({
  type: SET_BLOG_POST_DATA,
  payload: data
});
