import {
  SET_LESSON_LOADING,
  SET_LESSON_DATA,
  SET_SELECTED_LESSON,
  SET_REDIRECT_URL,
  UPDATE_UPVOTES,
  CLEAR_STATE,
  UPDATE_COMMENTS
  // DELETE_COMMENT
} from './types';

export const lessonInitialState = {
  lessonData: {},
  redirectURL: null,
  selectedLesson: '',
  isLoading: false
};

const reducer = (state = lessonInitialState, action) => {
  const { type, payload } = action;
  const { lessonData } = state;

  switch (type) {
    case SET_LESSON_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case SET_LESSON_DATA:
      return {
        ...state,
        lessonData: payload,
        isLoading: false
      };
    case SET_SELECTED_LESSON:
      return {
        ...state,
        selectedLesson: payload
      };

    case SET_REDIRECT_URL:
      return {
        ...state,
        redirectURL: payload
      };
    case UPDATE_UPVOTES:
      return {
        ...state,
        lessonData: {
          ...lessonData,
          upvotes: lessonData.upvotes.push(payload),
          lessonTipTotal: lessonData.lessonTipTotal + payload.amount
        }
      };
    case CLEAR_STATE:
      return lessonInitialState;

    case UPDATE_COMMENTS:
      return {
        ...state,
        lessonData: {
          ...lessonData,
          comments: payload
        },
        isLoading: false
      };

    default:
      return state;
  }
};

export default reducer;
