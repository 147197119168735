import {
  SET_POSTS_LOADING,
  SET_POSTS,
  ADD_POST,
  DELETE_POST_BY_ID,
  SET_SELECTED_SORT,
  SET_GALLERY
} from './types';

import { defaultGallery } from './constants';

import { insertPost, removePost, setSort } from './utils';

export const postsInitialState = {
  postGroups: [],
  isLoading: false,
  cursor: null,
  more: true,
  gallery: defaultGallery
};

const reducer = (state = postsInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_POSTS_LOADING:
      return {
        ...state,
        isLoading: payload
      };

    case SET_POSTS:
      return {
        ...state,
        postGroups: [...state.postGroups, ...payload.posts],
        isLoading: false,
        cursor: payload.cursor,
        more: payload.more
      };

    case ADD_POST:
      return {
        ...state,
        postGroups: insertPost(payload, state.postGroups),
        isLoading: false
      };

    case DELETE_POST_BY_ID:
      return {
        ...state,
        postGroups: removePost(payload, state.postGroups),
        isLoading: false
      };

    case SET_SELECTED_SORT:
      const { groupDate, sortMethod } = payload;

      return {
        ...state,
        postGroups: setSort(groupDate, sortMethod, state.postGroups)
      };

    case SET_GALLERY:
      return {
        ...state,
        gallery: payload
      };

    default:
      return state;
  }
};

export default reducer;
