import {
  SET_POST_LOADING,
  SET_POST_NOT_FOUND,
  SET_POST_DATA,
  SET_SELECTED_POST,
  SET_ADJACENT_POST_IDS,
  SET_REDIRECT_URL,
  UPDATE_UPVOTES,
  CLEAR_STATE,
  UPDATE_COMMENTS,
  DELETE_COMMENT
} from './types';

export const postInitialState = {
  postData: {},
  redirectURL: null,
  selectedPost: '',
  adjacentPostIds: {
    prev: '',
    next: ''
  },
  isLoading: false,
  postNotFound: false
};

const removeComment = (commentId, comments) =>
  comments.filter(comment => comment._id !== commentId);

const reducer = (state = postInitialState, action) => {
  const { type, payload } = action;
  const { postData } = state;

  switch (type) {
    case SET_POST_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_POST_NOT_FOUND:
      return {
        ...state,
        postNotFound: payload
      };
    case SET_POST_DATA:
      return {
        ...state,
        postData: payload,
        isLoading: false
      };
    case SET_SELECTED_POST:
      return {
        ...state,
        selectedPost: payload
      };
    case SET_ADJACENT_POST_IDS:
      return {
        ...state,
        adjacentPostIds: payload
      };
    case SET_REDIRECT_URL:
      return {
        ...state,
        redirectURL: payload
      };
    case UPDATE_UPVOTES:
      return {
        ...state,
        postData: {
          ...postData,
          upvotes: postData.upvotes.push(payload),
          postTipTotal: postData.postTipTotal + payload.amount
        }
      };
    case CLEAR_STATE:
      return postInitialState;

    case UPDATE_COMMENTS:
      return {
        ...state,
        postData: {
          ...postData,
          comments: payload
        },
        isLoading: false
      };
    case DELETE_COMMENT:
      return {
        ...state,
        postData: {
          ...postData,
          comments: removeComment(payload, state.postData.comments)
        },
        isLoading: false
      };
    default:
      return state;
  }
};

export default reducer;
