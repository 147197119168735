import axios from 'axios';
import _ from 'lodash';

import { alertOperations } from '../alert';
import { defaultErrorMessage } from '../alert/constants';
import { userOperations } from '../user';
import {
  setPostsLoading,
  setPosts,
  deletePostById,
  setSelectedSort,
  setGallery
} from './actions';

const { createAlert } = alertOperations;
const { setUser } = userOperations;

// Get post groups
export const getPostGroups = () => async (dispatch, getState) => {
  try {
    const { cursor, isLoading } = getState().posts;

    if (isLoading) return;

    dispatch(setPostsLoading(true));

    const url = cursor
      ? `/api/posts/groups?cursor=${cursor}`
      : '/api/posts/groups';

    // request posts
    const { data } = await axios.get(url);

    await dispatch(setPosts(data.postGroups, data.cursor, data.more));
    dispatch(setGalleryImages());
  } catch (error) {
    console.log(error);
    dispatch(setPostsLoading(false));
    dispatch(createAlert('Error fetching post groups', 'negative'));
  }
};

// Delete post
export const deletePost = postId => async dispatch => {
  try {
    // delete post from database
    await axios.delete(`/api/posts/${postId}`);

    // delete post data from loaded posts
    await dispatch(deletePostById(postId));

    await dispatch(setUser());

    await dispatch(createAlert('SUCCESS: Post removed', 'success'));

    return true;
  } catch (error) {
    if (error.response) {
      const errors = error.response.data.errors;
      errors.forEach(error => dispatch(createAlert(error.msg, 'negative')));
    } else {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }

    return false;
  }
};

// Pull random images from loaded posts for photo gallery
export const setGalleryImages = () => async (dispatch, getState) => {
  const { posts } = await getState();

  const galleryImages = [];

  await posts.postGroups.map(postGroup => {
    return postGroup.groupPosts.map(post => {
      return !post.isGated && post.photoPublicId
        ? galleryImages.push(post.photoPublicId)
        : null;
    });
  });

  if (galleryImages.length < 9) {
    galleryImages.push(...posts.gallery);
  }

  const sampleImages = _.sampleSize(galleryImages, 9);
  dispatch(setGallery(sampleImages));
};

// Set sort method for post group
export const setGroupSelectedSort = (groupDate, sortMethod) => dispatch => {
  dispatch(setSelectedSort(groupDate, sortMethod));
};
