import { createClient } from 'contentful';

import { setBlogData, setBlogPostData, setBlogLoading } from './actions';

import { timeFromNow } from '../../util/time';
import { CONTENTFUL_ACCESS_KEY, CONTENTFUL_SPACE_ID } from '../../config';

export const contentfulClient = createClient({
  space: CONTENTFUL_SPACE_ID,
  accessToken: CONTENTFUL_ACCESS_KEY
});

export async function fetchBlogPosts(skip, limit) {
  try {
    const response = await contentfulClient.getEntries({
      content_type: 'blogPost',
      order: '-sys.createdAt',
      skip,
      limit
    });

    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function fetchBlogPost(slug) {
  try {
    const { items } = await contentfulClient.getEntries({
      content_type: 'blogPost',
      'fields.slug': slug
    });

    return items[0];
  } catch (error) {
    console.log(error);
  }
}

export const getBlogPosts = () => async (dispatch, getState) => {
  const {
    blog: { blogPostsSkip, blogPostsTotal, isLoading }
  } = await getState();

  if (isLoading || blogPostsSkip === blogPostsTotal) return;

  dispatch(setBlogLoading(true));

  try {
    const limit = window.innerWidth > 768 ? 7 : 3;
    // console.log('limit: ', limit);

    const response = await fetchBlogPosts(blogPostsSkip, limit);
    // console.log('response: ', response);

    await dispatch(setBlogData(response));
  } catch (error) {
    console.log('error response:', error);

    dispatch(setBlogLoading(false));
  }
};

export const getBlogPost = slug => async dispatch => {
  dispatch(setBlogLoading(true));

  try {
    const post = await fetchBlogPost(slug);
    // console.log('post: ', post);

    const postData = parseBlogPostData(post);

    await dispatch(setBlogPostData(postData));
  } catch (error) {
    console.log('error response:', error);

    dispatch(setBlogLoading(false));
  }
};

export const parseBlogPostData = blogPost => {
  const {
    fields: {
      authorName,
      authorAvatarUrl,
      content,
      featuredImage,
      slug,
      subtitle,
      title
    },
    sys: { id, createdAt }
  } = blogPost;

  const timestamp = timeFromNow(createdAt);

  const itemData = {
    authorName,
    authorAvatarUrl,
    content,
    id,
    slug,
    subtitle,
    imageUrl: featuredImage.fields.file.url,
    timestamp,
    title
  };

  return itemData;
};

export const parseBlogItemData = blogPost => {
  const {
    fields: { slug, subtitle, thumbnail, title },
    sys: { id, createdAt }
  } = blogPost;

  const timestamp = timeFromNow(createdAt);

  const itemData = {
    id,
    slug,
    subtitle,
    thumbnailUrl: thumbnail.fields.file.url,
    timestamp,
    title
  };

  return itemData;
};
