import React from 'react';
import { useSelector } from 'react-redux';

import { Icon, Message } from 'semantic-ui-react';

const Alert = () => {
  const alerts = useSelector(state => state.alert);

  return (
    alerts !== null &&
    alerts.length > 0 &&
    alerts.map(alert => (
      <Message key={alert.id} className={`alert ${alert.alertType}`} icon>
        <Icon
          name={
            alert.alertType === 'positive' || alert.alertType === 'success'
              ? 'check circle'
              : 'warning sign'
          }
          size='small'
        />

        <Message.Header>{alert.msg}</Message.Header>
      </Message>
    ))
  );
};

export default Alert;
