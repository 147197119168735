import { memo } from 'react';

import { Popover } from '@headlessui/react';

import MarkAllReadPopover from './MarkAllReadPopover';
import NotificationItem from './NotificationItem';

const NotificationsFeed = ({ notifications }) => {
  const hasUnread = notifications.some(
    notification => !notification.hasBeenRead
  );

  const unreadCount = notifications.reduce((acc, notification) => {
    if (!notification.hasBeenRead) {
      acc += 1;
    }
    return acc;
  }, 0);

  const noNotifications = notifications.length === 0;

  return (
    <Popover.Panel className='absolute -left-48 z-10 mt-1 w-80 overflow-auto rounded-md shadow-lg  bg-white py-1'>
      {({ close }) => (
        <div>
          <MarkAllReadPopover
            count={unreadCount}
            isDisabled={!hasUnread || noNotifications}
          />

          <ul className='max-h-[300px] overflow-y-auto divide-y divide-gray-200'>
            {notifications.length > 0 ? (
              notifications.map(notification => (
                <NotificationItem
                  key={notification._id}
                  notification={notification}
                  close={close}
                />
              ))
            ) : (
              <li className='p-2'>
                <div className='flex space-x-3'>
                  <div className='flex-1 space-y-1 pl-2 text-sm text-gray-700 uppercase'>
                    None
                  </div>
                </div>
              </li>
            )}
          </ul>
        </div>
      )}
    </Popover.Panel>
  );
};

export default memo(NotificationsFeed);
