import {
  SET_POSTS_LOADING,
  SET_POSTS,
  ADD_POST,
  DELETE_POST_BY_ID,
  SET_SELECTED_SORT,
  SET_GALLERY
} from './types';

export const setPostsLoading = status => ({
  type: SET_POSTS_LOADING,
  payload: status
});

export const setPosts = (posts, cursor, more) => ({
  type: SET_POSTS,
  payload: {
    posts,
    cursor,
    more
  }
});

export const addPost = post => ({
  type: ADD_POST,
  payload: post
});

export const deletePostById = id => ({
  type: DELETE_POST_BY_ID,
  payload: id
});

export const setSelectedSort = (groupDate, sortMethod) => ({
  type: SET_SELECTED_SORT,
  payload: { groupDate, sortMethod }
});

export const setGallery = gallery => ({
  type: SET_GALLERY,
  payload: gallery
});
