import {
  addMessage,
  // addMessages,
  setMessages,
  clearMessages,
  setUsers
} from './actions';

import { mergeMessages } from '../../util/message';

export const addChatMessage = message => dispatch =>
  dispatch(addMessage(message));

export const addChatMessages = newMessages => async (dispatch, getState) => {
  // get current messages from state
  const {
    chat: { messages }
  } = await getState();
  // merge new messages with current messages w/o duplicates
  const updatedMessages = mergeMessages(messages, newMessages);

  dispatch(setMessages(updatedMessages));
};

export const setChatMessages = messages => dispatch =>
  dispatch(setMessages(messages));

export const clearChatMessages = () => dispatch => dispatch(clearMessages());

export const setChatUsers = users => dispatch => dispatch(setUsers(users));
