import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { notificationsOps } from '../../redux/notifications';

import { timeFromNow } from '../../util/time';

const NotificationItem = ({ notification, close }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { markAsRead } = notificationsOps;

  const {
    _id,
    targetType,
    targetId,
    commentAuthor,
    commentAuthorAvatar,
    commentText,
    commentTimestamp,
    hasBeenRead,
    isReply
  } = notification;

  const handleClick = () => {
    if (!hasBeenRead) {
      dispatch(markAsRead(_id));
    }

    history.push(`/${targetType}/${targetId}`);
    close(null);
  };

  const action = isReply
    ? 'replied to your comment: '
    : targetType === 'posts'
    ? 'commented on your post: '
    : 'commented on your lesson: ';

  const text =
    commentText.length > 78 ? `${commentText.slice(0, 78)}...` : commentText;

  const timestamp = timeFromNow(commentTimestamp);

  return (
    <li key={_id} className='px-2 py-2'>
      <div className='flex space-x-3'>
        <div className='flex-shrink-0'>
          <img
            className='h-6 w-6 rounded-full'
            src={commentAuthorAvatar}
            alt='user avatar'
          />
        </div>
        <div className='w-[236px] flex-1 space-y-1'>
          <div
            onClick={handleClick}
            className={classnames('flex flex-col text-sm cursor-pointer', {
              'text-gray-400': hasBeenRead,
              'text-gray-500': !hasBeenRead
            })}
          >
            <div className='text-left'>
              <span
                className={classnames('text-[#028090]', {
                  'font-bold': !hasBeenRead
                })}
              >
                {commentAuthor}
              </span>

              <span className='pl-1'>{action}</span>

              <span className='font-bold'>{text}</span>
            </div>
            <div className='flex items-center gap-2 mt-1'>
              <span
                className={classnames(
                  'flex items-center justify-center h-4 w-4 ',
                  {
                    'text-gray-400': hasBeenRead,
                    'text-green-600': !hasBeenRead
                  }
                )}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  className='w-4 h-4'
                >
                  <path
                    fillRule='evenodd'
                    d='M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97-1.94.284-3.916.455-5.922.505a.39.39 0 00-.266.112L8.78 21.53A.75.75 0 017.5 21v-3.955a48.842 48.842 0 01-2.652-.316c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97z'
                    clipRule='evenodd'
                  />
                </svg>
              </span>
              <span className='text-gray-500'>{timestamp}</span>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default memo(NotificationItem);
