import axios from 'axios';

import { alertOperations } from '../alert';
import { defaultErrorMessage } from '../alert/constants';
import { userOperations } from '../user';
import {
  deleteChallengeById,
  setChallenges,
  setChallengesLoading
} from './actions';

const { createAlert } = alertOperations;
const { setUser } = userOperations;

// Get challenges
export const getChallenges = () => async (dispatch, getState) => {
  const { challenges } = getState();
  const { isLoading } = challenges;
  if (isLoading) return;

  dispatch(setChallengesLoading(true));

  try {
    const { data } = await axios.get(`/api/challenges`);

    await dispatch(setChallenges(data.challenges));

    return { success: true };
  } catch (error) {
    console.log(error);
    dispatch(setChallengesLoading(false));
    dispatch(
      createAlert(
        'Oops, something went wrong - please try again later',
        'negative'
      )
    );

    return { success: false };
  }
};

// Delete challenge
export const deleteChallenge = challengeId => async dispatch => {
  dispatch(setChallengesLoading(true));

  try {
    // delete challenge from database
    await axios.delete(`/api/challenges/${challengeId}`);

    // delete challenge data from loaded challenges
    await dispatch(deleteChallengeById(challengeId));

    await dispatch(setUser());

    await dispatch(createAlert('Challenge removed', 'success'));

    return true;
  } catch (error) {
    console.log(error);

    dispatch(setChallengesLoading(false));
    dispatch(createAlert(defaultErrorMessage, 'negative'));

    return false;
  }
};
