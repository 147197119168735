import { alertInitialState } from './alert/reducer';
import { blogInitialState } from './blog/reducer';
import { challengeInitialState } from './challenge/reducer';
import { chalSubmitFormInitialState } from './chal-submit-form/reducer';
import { challengesInitialState } from './challenges/reducer';
import { chatInitialState } from './chat/reducer';
import { leaderboardsInitialState } from './leaderboards/reducer';
import { lessonInitialState } from './lesson/reducer';
import { lessonFormInitialState } from './lesson-form/reducer';
import { lessonsInitialState } from './lessons/reducer';
import { notificationsInitialState } from './notifications/reducer';
import { postInitialState } from './post/reducer';
import { postFormInitialState } from './post-form/reducer';
import { postsInitialState } from './posts/reducer';
import { profileInitialState } from './profile/reducer';
import { settingsInitialState } from './settings/reducer';
import { statsInitialState } from './stats/reducer';
import { userInitialState } from './user/reducer';

const initialState = {
  alert: alertInitialState,
  blog: blogInitialState,
  challenge: challengeInitialState,
  chalSubmitForm: chalSubmitFormInitialState,
  challenges: challengesInitialState,
  chat: chatInitialState,
  leaderboards: leaderboardsInitialState,
  lesson: lessonInitialState,
  lessonForm: lessonFormInitialState,
  lessons: lessonsInitialState,
  notifications: notificationsInitialState,
  post: postInitialState,
  postForm: postFormInitialState,
  posts: postsInitialState,
  profile: profileInitialState,
  settings: settingsInitialState,
  stats: statsInitialState,
  user: userInitialState
};

export default initialState;
