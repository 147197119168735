import {
  SET_LEADERBOARDS_DATA,
  SET_LEADERBOARDS_LOADING,
  SET_LEADERBOARDS_TIMESTAMP
} from './types';

export const leaderboardsInitialState = {
  leaderboards: [],
  lastUpdatedAt: '',
  timestamp: '',
  isLoading: false
};

const reducer = (state = leaderboardsInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_LEADERBOARDS_DATA:
      return {
        ...state,
        leaderboards: payload,
        isLoading: false
      };

    case SET_LEADERBOARDS_LOADING:
      return {
        ...state,
        isLoading: payload
      };

    case SET_LEADERBOARDS_TIMESTAMP:
      return {
        ...state,
        lastUpdatedAt: payload.lastUpdatedAt,
        timestamp: payload.timestamp
      };

    default:
      return state;
  }
};

export default reducer;
