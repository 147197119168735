import moment from 'moment';

export const timeFromNow = date => moment(date).fromNow();

export const dateIsBefore = (date1, date2) => moment(date1).isBefore(date2);

export const dateIsSameOrAfter = (date1, date2) =>
  moment(date1).isSameOrAfter(date2);

export const dateIsToday = date => moment(date).isSame(new Date(), 'day');

export const getCurrentDate = () => moment();

export const getFormattedDate = date => moment(date).format();

export const addDays = (date, days) => moment(date).add(days, 'days');

export const getStartOfTodayUTC = () => {
  const date = new Date();
  date.setUTCHours(0, 0, 0, 0);
  return date;
};
