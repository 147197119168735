import { Select } from 'semantic-ui-react';

import { setLocalStorageItem } from '../../util/local-storage';
import { filterOptions } from '../../util/post-filter';

const PostFilter = ({ filter, setFilter }) => {
  console.log('filter: ', filter);

  const handleFilter = (e, { value }) => {
    setLocalStorageItem('postFeedFilter', value);
    setFilter(value);
  };

  return (
    <div className='filter-wrapper flex items-center justify-end gap-1 mt-1 mr-1 lg:mt-4 lg:mr-4'>
      <span className='text-black/[0.6]'>Filter:</span>
      <Select
        options={filterOptions}
        value={filter}
        icon='chevron down'
        onChange={handleFilter}
      />
    </div>
  );
};

export default PostFilter;
