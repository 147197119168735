import {
  ADD_CHALLENGE,
  DELETE_CHALLENGE_BY_ID,
  SET_CHALLENGES,
  SET_CHALLENGES_LOADING
} from './types';

export const challengesInitialState = {
  challenges: [],
  isLoading: false
};

const removeChallenge = (challengeId, challenges) => {
  const updatedChallenges = challenges.filter(
    challenge => challenge._id !== challengeId
  );

  return updatedChallenges;
};

const reducer = (state = challengesInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CHALLENGES_LOADING:
      return {
        ...state,
        isLoading: payload
      };

    case SET_CHALLENGES:
      return {
        ...state,
        challenges: payload,
        isLoading: false
      };

    case ADD_CHALLENGE:
      return {
        ...state,
        challenges: state.challenges.push(payload),
        isLoading: false
      };

    case DELETE_CHALLENGE_BY_ID:
      return {
        ...state,
        challenges: removeChallenge(payload, state.challenges),
        isLoading: false
      };

    default:
      return state;
  }
};

export default reducer;
