import axios from 'axios';

import { alertOperations } from '../alert';
import { defaultErrorMessage } from '../alert/constants';
import {
  setPostLoading,
  setPostNotFound,
  setPostData,
  setSelectedPost,
  setAdjacentPostIds,
  setRedirectURL,
  clearState,
  updateComments,
  deleteComment
} from './actions';

import {
  getDateOnly,
  getGroupIndexByDate,
  sortPostsByMethod,
  findAdjacentPostIds,
  getDefaultAdjacentPostIds
} from '../../util/posts';

const { createAlert } = alertOperations;

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

// Get post
export const getPostById = postId => async dispatch => {
  dispatch(setPostLoading(true));

  try {
    const res = await axios.get(`/api/posts/${postId}`);

    await dispatch(setPostData(res.data));
  } catch (error) {
    console.log(error);
    dispatch(setPostLoading(false));
    dispatch(setPostNotFound(true));

    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }
  }
};

// Clear post data
export const clearPostData = () => dispatch => {
  dispatch(setPostData({}));
};

// Clear post state
export const clearPostState = () => dispatch => {
  dispatch(clearState());
};

// Add Tip
export const addTip = (postId, amount) => async (dispatch, getState) => {
  const {
    user: { currentUser }
  } = getState();

  if (!postId) {
    dispatch(
      createAlert(
        'Sorry, something went wrong... please try again later',
        'negative'
      )
    );

    return;
  }

  const tipData = {
    userId: currentUser._id,
    amount
  };

  if (currentUser.x_user_token) {
    tipData.x_user_token = currentUser.x_user_token;
  }

  const body = JSON.stringify(tipData);

  try {
    const res = await axios.put(`/api/xumm/tip/${postId}`, body, config);

    const redirectURL = res.data.payloadURL;
    dispatch(setRedirectURL(redirectURL));
  } catch (error) {
    const errorMsg =
      error.response && error.response.data.errors
        ? error.response.data.errors[0].msg
        : defaultErrorMessage;

    await dispatch(createAlert(errorMsg, 'negative'));
    // delay redirect because it kills alert prematurely
    setTimeout(() => dispatch(setRedirectURL(`/posts/${postId}`)), 3000);
  }
};

// For New Post Tip API
// Add Tip
export const tipPost = (postId, amount) => async dispatch => {
  try {
    if (!postId) {
      dispatch(
        createAlert(
          'Sorry, something went wrong... please try again later',
          'negative'
        )
      );

      return;
    }

    const tipData = {
      amount
    };

    const body = JSON.stringify({ tipData });

    const res = await axios.put(`/api/xumm/tip/post/${postId}`, body, config);

    const redirectURL = res.data.payloadURL;
    dispatch(setRedirectURL(redirectURL));
  } catch (error) {
    const errorMsg = error.message ? error.message : defaultErrorMessage;

    await dispatch(createAlert(errorMsg, 'negative'));
    // delay redirect because it kills alert prematurely
    setTimeout(() => dispatch(setRedirectURL(`/posts/${postId}`)), 3000);
  }
};

// Set selected post
export const setSelectedPostById = postId => dispatch => {
  dispatch(setSelectedPost(postId));
};

// Ping xumm server
export const pingServerX = () => async dispatch => {
  try {
    const res = await axios.get('/api/xumm/ping');
    return res;
  } catch ({ response }) {
    const errors = response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(createAlert(error.msg, 'negative')));
    }
  }
};

// Add comment
export const addComment = (postId, text) => async dispatch => {
  dispatch(setPostLoading(true));

  const commentData = {
    text
  };

  const body = JSON.stringify(commentData);

  try {
    const res = await axios.post(`/api/posts/${postId}/comment`, body, config);

    await dispatch(createAlert('SUCCESS: New comment created', 'success'));
    await dispatch(updateComments(res.data));
  } catch (error) {
    dispatch(setPostLoading(false));
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }
  }
};

// Edit comment
export const editComment = (postId, commentId, text) => async dispatch => {
  dispatch(setPostLoading(true));

  const commentData = {
    text
  };

  const body = JSON.stringify(commentData);

  try {
    const res = await axios.put(
      `/api/posts/${postId}/${commentId}`,
      body,
      config
    );

    await dispatch(createAlert('SUCCESS: Comment updated', 'success'));

    await dispatch(updateComments(res.data));
  } catch (error) {
    dispatch(setPostLoading(false));
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }
  }
};

// Delete comment
export const deleteCommentById = (postId, commentId) => async dispatch => {
  dispatch(setPostLoading());

  try {
    await axios.delete(`/api/posts/${postId}/${commentId}`);

    dispatch(deleteComment(commentId));
    dispatch(createAlert('SUCCESS: Comment removed', 'success'));

    return true;
  } catch (error) {
    dispatch(setPostLoading(false));
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }

    return false;
  }
};

// Add reply to comment
export const addReply = (postId, commentId, text) => async dispatch => {
  dispatch(setPostLoading());

  const replyData = {
    text
  };

  const body = JSON.stringify(replyData);

  try {
    const res = await axios.post(
      `/api/posts/${postId}/${commentId}/reply`,
      body,
      config
    );

    await dispatch(createAlert('SUCCESS: New reply created', 'success'));
    await dispatch(updateComments(res.data));
  } catch (error) {
    dispatch(setPostLoading(false));
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }
  }
};

// Edit reply
export const editReply =
  (postId, commentId, replyId, text) => async dispatch => {
    dispatch(setPostLoading());

    const commentData = {
      text
    };

    const body = JSON.stringify(commentData);

    try {
      const res = await axios.put(
        `/api/posts/${postId}/${commentId}/${replyId}`,
        body,
        config
      );

      await dispatch(createAlert('SUCCESS: Comment updated', 'success'));

      await dispatch(updateComments(res.data));
    } catch (error) {
      dispatch(setPostLoading(false));
      if (error) {
        dispatch(createAlert(defaultErrorMessage, 'negative'));
      }
    }
  };

// Delete reply
export const deleteReplyById =
  (postId, commentId, replyId) => async dispatch => {
    dispatch(setPostLoading());

    try {
      const res = await axios.delete(
        `/api/posts/${postId}/${commentId}/${replyId}`
      );

      dispatch(updateComments(res.data));
      dispatch(createAlert('SUCCESS: Reply removed', 'success'));

      return true;
    } catch (error) {
      dispatch(setPostLoading(false));
      if (error) {
        dispatch(createAlert(defaultErrorMessage, 'negative'));
      }

      return false;
    }
  };

// Get IDs of previous/next posts with selected sort
export const getAdjacentPostIds = () => async (dispatch, getState) => {
  const { post, posts } = getState();
  const { postData } = post;
  const { postGroups } = posts;
  const postNavIds = {
    prev: null,
    next: null
  };

  // get date only from post data
  const postDateOnly = getDateOnly(postData.date);

  // get matching post group
  const groupIndex = getGroupIndexByDate(postDateOnly, postGroups);

  // if groupIndex == -1, then post isn't in postGroups
  // get top post in post feed and set as previous/next
  if (groupIndex === -1) {
    const { previous, next } = getDefaultAdjacentPostIds(postGroups[0]);

    postNavIds.prev = previous;
    postNavIds.next = next;
  } else {
    const postGroup = postGroups[groupIndex];

    // get adjacent post ids using post ID and group
    const adjacentPostIds = findAdjacentPostIds(postData._id, postGroup);

    // if previous exists, set in results object
    if (adjacentPostIds.previous) {
      postNavIds.prev = adjacentPostIds.previous;
      // if inside first group then no previous group/post
    } else if (groupIndex === 0) {
      postNavIds.prev = null;
      // if no previous and not first group
    } else {
      // get previous post group
      const previousGroup = postGroups[groupIndex - 1];
      // sort posts of previous group
      const sortedPosts = sortPostsByMethod(
        previousGroup.selectedSort,
        previousGroup.groupPosts
      );
      // get ID of last post in sorted group
      postNavIds.prev = sortedPosts[sortedPosts.length - 1]._id;
    }

    // if next exists, set in results object
    if (adjacentPostIds.next) {
      postNavIds.next = adjacentPostIds.next;
      // if inside last group then no next group/post
    } else if (groupIndex === postGroups.length - 1) {
      postNavIds.next = null;
      // if no next and not first group
    } else {
      // get next post group
      const nextGroup = postGroups[groupIndex + 1];
      // sort posts of next group
      const sortedPosts = sortPostsByMethod(
        nextGroup.selectedSort,
        nextGroup.groupPosts
      );
      // get ID of first post in sorted group
      postNavIds.next = sortedPosts[0]._id;
    }
  }

  dispatch(setAdjacentPostIds(postNavIds));
};
