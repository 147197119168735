import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Menu, Icon, TransitionablePortal } from 'semantic-ui-react';

import { signOutUser } from '../../redux/user/operations';

const HeaderMenu = () => {
  const username = useSelector(state => state.user.currentUser.username);

  const history = useHistory();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const togglePortal = () => setIsOpen(!isOpen);
  const handleClose = () => setIsOpen(false);

  const handleSignOut = () => {
    dispatch(signOutUser());
    handleClose();
    history.push('/');
  };

  const handleSignIn = () => {
    handleClose();
    history.push('/signin');
  };

  const handleMenuClick = route => {
    handleClose();
    history.push(route);
  };

  return (
    <div className='header__menu header__menu-user'>
      <Icon
        name='ellipsis horizontal'
        className='header__menu-icon'
        circular
        onClick={togglePortal}
      />

      <TransitionablePortal onClose={handleClose} open={isOpen}>
        <Menu className='header__menu-component' vertical>
          <Menu.Item onClick={() => handleMenuClick('/home')}>Home</Menu.Item>

          <Menu.Item onClick={() => handleMenuClick('/')}>Posts</Menu.Item>

          {username && (
            <>
              <Menu.Item onClick={() => handleMenuClick('/account')}>
                Account
              </Menu.Item>
              <Menu.Item
                onClick={() => handleMenuClick(`/profile/${username}`)}
              >
                Profile
              </Menu.Item>
            </>
          )}

          <Menu.Item onClick={() => handleMenuClick('/leaderboards')}>
            Leaderboards
          </Menu.Item>

          <Menu.Item onClick={() => handleMenuClick('/learn')}>Learn</Menu.Item>

          <Menu.Item onClick={() => handleMenuClick('/challenges')}>
            Challenges
          </Menu.Item>

          <Menu.Item onClick={() => handleMenuClick('/chat')}>Chat</Menu.Item>

          <Menu.Item href='https://ixgl.gfam.live' target='_blank'>
            IXGL App
          </Menu.Item>

          <Menu.Item onClick={() => handleMenuClick('/welcome')}>
            Welcome
          </Menu.Item>

          <Menu.Item
            onClick={() =>
              handleMenuClick('/blog/frequently-asked-questions-faq')
            }
          >
            FAQ
          </Menu.Item>

          <Menu.Item onClick={() => handleMenuClick('/blog')}>Blog</Menu.Item>

          <Menu.Item onClick={() => handleMenuClick('/support')}>
            Support
          </Menu.Item>

          {username ? (
            <Menu.Item onClick={handleSignOut}>Sign Out</Menu.Item>
          ) : (
            <Menu.Item onClick={handleSignIn}>Sign In</Menu.Item>
          )}
        </Menu>
      </TransitionablePortal>
    </div>
  );
};

export default HeaderMenu;
