import React from 'react';
import classnames from 'classnames';

import { Icon } from 'semantic-ui-react';

const VerifiedIcon = ({
  isVerified,
  viewerIsUser,
  iconSize,
  isVerifiedClass,
  notVerifiedClass
}) => {
  const title = isVerified
    ? 'This account has been verified'
    : viewerIsUser
    ? 'Get verified - contact us support@gfam.live'
    : 'This account has not been verified';

  const classes = classnames('verified-icon', {
    [isVerifiedClass]: isVerified,
    [notVerifiedClass]: !isVerified
  });

  return (
    <Icon
      className={classes}
      size={iconSize}
      name='check circle'
      title={title}
    />
  );
};

export default VerifiedIcon;
