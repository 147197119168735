import {
  SET_IMAGE_FILE,
  SET_IMAGE_URL,
  SET_PREVIEW_IMAGE,
  SET_CONTENT,
  SET_UPLOADING,
  CLEAR_PHOTO,
  CLEAR_FORM_STATE,
  LOAD_FORM_STATE
} from './types';

export const chalSubmitFormInitialState = {
  imageFile: null,
  imageURL: '',
  previewImage: {
    id: '',
    url: ''
  },
  content: '',
  uploading: false,
  challengeId: '',
  responseId: '',
  dataLoaded: false
};

const reducer = (state = chalSubmitFormInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_IMAGE_FILE:
      return {
        ...state,
        imageFile: payload
      };

    case SET_IMAGE_URL:
      return {
        ...state,
        imageURL: payload
      };

    case SET_PREVIEW_IMAGE:
      return {
        ...state,
        previewImage: payload
      };

    case SET_CONTENT:
      return {
        ...state,
        content: payload
      };

    case SET_UPLOADING:
      return {
        ...state,
        uploading: payload
      };

    case CLEAR_PHOTO:
      return {
        ...state,
        imageFile: null,
        imageURL: '',
        previewImage: {
          id: '',
          url: ''
        }
      };

    case CLEAR_FORM_STATE:
      return chalSubmitFormInitialState;

    case LOAD_FORM_STATE:
      const { challengeId, _id, photoURL, content } = payload;
      return {
        ...state,
        previewImage: {
          id: _id,
          url: photoURL
        },
        content,
        challengeId,
        responseId: _id,
        dataLoaded: true
      };

    default:
      return state;
  }
};

export default reducer;
