import axios from 'axios';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { alertOperations } from '../alert';
import { defaultErrorMessage } from '../alert/constants';
import { userOperations } from '../user';
import {
  setImageFile,
  setImageURL,
  setPreviewImage,
  setDescription,
  setStory,
  setIsGated,
  setGateFee,
  setUploading,
  setUploadSuccess,
  clearFormState,
  loadFormState
} from './actions';

import cloudinary from '../../services/cloudinary';

const { createAlert } = alertOperations;
const { setUser } = userOperations;

// set post form image file
export const setPostImage = file => dispatch => {
  if (!file) {
    dispatch(
      createAlert(
        'Sorry, your image file is over 5MG or an unsupported type.',
        'negative'
      )
    );

    return;
  }

  try {
    const reader = new FileReader();
    reader.onload = function (e) {
      dispatch(setPreviewImage(_.uniqueId(), e.target.result));
    };

    reader.readAsDataURL(file);

    dispatch(setImageFile(file));
  } catch (error) {
    console.error(error);
    dispatch(
      createAlert(
        'Sorry, something went wrong. Please check your image file.',
        'negative'
      )
    );
  }
};

export const setPostText = text => dispatch => {
  dispatch(setDescription(text));
};

export const setPostStory = text => dispatch => {
  dispatch(setStory(text));
};

export const setPostIsGated = status => dispatch => {
  dispatch(setIsGated(status));
};

export const setPostGateFee = fee => dispatch => {
  dispatch(setGateFee(fee));
};

export const uploadPhoto =
  ({ userId, imageFile }) =>
  async dispatch => {
    if (!userId || !imageFile) {
      return;
    }

    await dispatch(setUploading(true));

    const publicId = `${userId}/${uuidv4()}`;

    const instance = axios.create();
    delete instance.defaults.headers.common['x-auth-token'];

    const requestData = {
      file: imageFile,
      upload_preset: cloudinary.config.upload_preset,
      public_id: publicId
    };

    try {
      const res = await instance.post(
        cloudinary.config.upload_url,
        requestData
      );

      const { secure_url, public_id } = res.data;

      await dispatch(setImageURL(secure_url));

      await dispatch(createPost(public_id));
    } catch (error) {
      console.error(error);
      dispatch(
        createAlert(
          'Image upload failed - Please check your image file',
          'negative'
        )
      );
      await dispatch(setUploading(false));
    }
  };

export const createPost = photoPublicId => async (dispatch, getState) => {
  const { user, postForm } = getState();

  const {
    currentUser: { _id, paymentAccount }
  } = user;

  const { description, story, isGated, gateFee } = postForm;

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const postData = {
    user: _id,
    paymentAccount,
    description,
    story,
    isGated,
    gateFee,
    photoPublicId
  };

  const body = JSON.stringify(postData);

  try {
    const res = await axios.post('/api/posts', body, config);
    // console.log('create post result: ', res.data);
    await dispatch(setUser());

    await dispatch(setUploadSuccess(res.data._id));
  } catch (error) {
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }
  }
};

export const updatePost = () => async (dispatch, getState) => {
  const { postForm } = getState();

  const { postId, description, story, isGated, gateFee } = postForm;

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const data = {
    description,
    story,
    isGated,
    gateFee
  };

  const body = JSON.stringify({ data });

  try {
    await axios.put(`/api/posts/${postId}/edit`, body, config);

    await dispatch(setUser());

    await dispatch(setUploadSuccess(postId));
  } catch (error) {
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }
  }
};

export const clearPostForm = () => dispatch => dispatch(clearFormState());

export const loadPostData = postData => dispatch =>
  dispatch(loadFormState(postData));
