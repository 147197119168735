import axios from 'axios';

import {
  setLeaderboardsData,
  setLeaderboardsLoading,
  setLeaderboardsTimestamp
} from './actions';

import { timeFromNow, addDays, dateIsSameOrAfter } from '../../util/time';

export const getLeaderboards = () => async (dispatch, getState) => {
  const {
    leaderboards: { leaderboards, lastUpdatedAt }
  } = getState();

  const shouldUpdate = shouldUpdateLeaderboards(lastUpdatedAt);

  if (leaderboards.length !== 0 && !shouldUpdate) return;

  dispatch(setLeaderboardsLoading(true));

  try {
    // request leaderboards
    const res = await axios.get('/api/leaderboards');

    await dispatch(setLeaderboardsData(res.data.leaderboards));
    const lastUpdated = res.data.leaderboards[0].createdAt;
    const timestamp = getFromNowTimestamp(lastUpdated);

    await dispatch(setLeaderboardsTimestamp(lastUpdated, timestamp));
  } catch (error) {
    console.log('error response:', error);

    dispatch(setLeaderboardsLoading(false));
  }
};

export const getMobileMenuLabel = label => {
  switch (label) {
    case 'Comments':
    case 'Posts':
      return label;

    case 'Post Views':
      return 'Views';

    case 'Tips Given':
      return 'Tips';

    case 'Tips Received':
      return 'Tips Recd';

    default:
      return label;
  }
};

export const getFromNowTimestamp = dateString => {
  const timestamp = timeFromNow(dateString);

  return timestamp;
};

export const shouldUpdateLeaderboards = lastUpdatedAt => {
  const nextUpdate = addDays(lastUpdatedAt, 1);
  const timeToUpdate = dateIsSameOrAfter(null, nextUpdate);

  return timeToUpdate;
};
