import {
  SET_CURRENT_USER,
  UPDATE_CURRENT_USER,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT,
  SET_LOADING,
  SET_XUMM_AUTH_URL
} from './types';

export const setCurrentUser = user => ({
  type: SET_CURRENT_USER,
  payload: user
});

export const updateCurrentUser = user => ({
  type: UPDATE_CURRENT_USER,
  payload: user
});

export const registerSuccess = token => ({
  type: REGISTER_SUCCESS,
  payload: token
});

export const registerFailure = () => ({
  type: REGISTER_FAILURE
});

export const signInSuccess = token => ({
  type: SIGN_IN_SUCCESS,
  payload: token
});

export const signInFailure = () => ({
  type: SIGN_IN_FAILURE
});

export const signOut = () => ({
  type: SIGN_OUT
});

export const setLoading = status => ({
  type: SET_LOADING,
  payload: status
});

export const setXummAuthURL = authURL => ({
  type: SET_XUMM_AUTH_URL,
  payload: authURL
});
