import React from 'react';
import { useSelector } from 'react-redux';

import HeaderUser from '../HeaderUser';
import HeaderWithLogon from '../HeaderWithLogon';

const Header = () => {
  // check Auth status
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);

  if (!isAuthenticated) return <HeaderWithLogon />;

  return <HeaderUser />;
};

export default Header;
