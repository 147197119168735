export const insertPost = (post, groups) => {
  const postGroups = [];
  groups.map(group => {
    return postGroups.push(
      group.groupTitle === 'Today'
        ? { ...group, groupPosts: [post, ...group.groupPosts] }
        : group
    );
  });

  return postGroups;
};

export const removePost = (postId, groups) => {
  const postGroups = [];

  groups.map(group => {
    const posts = group.groupPosts.filter(post => post._id !== postId);
    const postGroup = {
      ...group,
      groupPosts: posts
    };

    return postGroups.push(postGroup);
  });

  return postGroups;
};

export const setSort = (groupDate, sortMethod, groups) => {
  const postGroups = [];
  groups.map(group => {
    return postGroups.push(
      group.groupDate === groupDate
        ? { ...group, selectedSort: sortMethod }
        : group
    );
  });

  return postGroups;
};
