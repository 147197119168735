import {
  SET_LEADERBOARDS_DATA,
  SET_LEADERBOARDS_LOADING,
  SET_LEADERBOARDS_TIMESTAMP
} from './types';

export const setLeaderboardsData = data => ({
  type: SET_LEADERBOARDS_DATA,
  payload: data
});

export const setLeaderboardsLoading = bool => ({
  type: SET_LEADERBOARDS_LOADING,
  payload: bool
});

export const setLeaderboardsTimestamp = (lastUpdatedAt, timestamp) => ({
  type: SET_LEADERBOARDS_TIMESTAMP,
  payload: { lastUpdatedAt, timestamp }
});
