import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';

import { settingsOperations } from './redux/settings';
import { userOperations } from './redux/user';
import setAuthToken from './util/set-auth-token';

import AppRoutes from './routes/AppRoutes';

class App extends Component {
  async componentDidMount() {
    const { checkAppSettings, setUser } = this.props;

    // if Auth token found, get user
    if (localStorage.token) {
      setAuthToken(localStorage.token);
      await setUser();
    }

    // get app settings
    // postingDisabled, isChatCoilGated, adsEnabled
    await checkAppSettings();
  }

  render() {
    return (
      <div className='w-full h-full overflow-hidden'>
        <Router>
          <AppRoutes />
        </Router>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  userLoaded: user.currentUser._id
});

const mapDispatchToProps = dispatch => ({
  checkAppSettings: () => dispatch(settingsOperations.checkAppSettings()),
  setUser: () => dispatch(userOperations.setUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
