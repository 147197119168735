import axios from 'axios';

import { addDays, dateIsBefore, getStartOfTodayUTC, timeFromNow } from './time';

// Check user's posting cooldown status
// @param lastPostTime - date string
// @returns { isCooldownActive: boolean, timeRemaining: string }
export function checkIsPostingCooldownActive(lastPostTime) {
  let timeRemaining = null;

  if (!lastPostTime) return { isCooldownActive: false };

  const startOfToday = getStartOfTodayUTC();

  const isCooldownActive = dateIsBefore(startOfToday, lastPostTime);

  if (isCooldownActive) {
    const startOfTomorrow = addDays(startOfToday, 1);
    timeRemaining = timeFromNow(startOfTomorrow);
  }

  return {
    isCooldownActive,
    timeRemaining
  };
}

// Check if user has tipped post
// upvotes [{_id, user: {displayName, _id, username}, amount, tip}]
export function findUserTip(upvotes, userId) {
  if (!upvotes || upvotes?.length < 1) return undefined;

  const userTip = upvotes.find(upvote => {
    if (!upvote.user) return false;

    return upvote.user._id === userId;
  });

  return !!userTip;
}

// upvotes [{_id, user: {ObjectId}, amount, tip}]
// used by PostItem (different upvote object than Post)
export function checkHasUserTipped(upvotes, userId) {
  if (!upvotes || upvotes?.length < 1) return false;

  const userTip = upvotes.find(tip => tip.user === userId);

  // coerce to bool
  return !!userTip;
}

// Verify user Xumm/payment address
export const verifyPaymentAddress = async address => {
  try {
    const res = await axios.get(`/api/xumm/verify/${address}`);
    // console.log('verified res.data: ', res.data);
    return res.data.verified;
  } catch (error) {
    console.log(error);

    return false;
  }
};
