import {
  SET_LESSON_LOADING,
  SET_LESSON_DATA,
  SET_SELECTED_LESSON,
  SET_REDIRECT_URL,
  UPDATE_UPVOTES,
  CLEAR_STATE,
  UPDATE_COMMENTS,
  DELETE_COMMENT
} from './types';

export const setLessonLoading = () => ({
  type: SET_LESSON_LOADING
});

export const setLessonData = lesson => ({
  type: SET_LESSON_DATA,
  payload: lesson
});

export const setSelectedLesson = id => ({
  type: SET_SELECTED_LESSON,
  payload: id
});

export const setRedirectURL = url => ({
  type: SET_REDIRECT_URL,
  payload: url
});

export const updateVotes = upvote => ({
  type: UPDATE_UPVOTES,
  payload: upvote
});

export const clearState = () => ({
  type: CLEAR_STATE
});

export const updateComments = comments => ({
  type: UPDATE_COMMENTS,
  payload: comments
});

export const deleteComment = id => ({
  type: DELETE_COMMENT,
  payload: id
});
