import { SET_LOADING, SET_NOTIFICATIONS } from './types';

export const setNotifications = notifications => ({
  type: SET_NOTIFICATIONS,
  payload: notifications
});

export const setIsLoading = isLoading => ({
  type: SET_LOADING,
  payload: isLoading
});
