import axios from 'axios';

import { alertOperations } from '../alert';
import { defaultErrorMessage } from '../alert/constants';
import {
  setChallengeLoading,
  setChallengeData,
  setChallengeResponses,
  clearState,
  updateComments
} from './actions';

const { createAlert } = alertOperations;

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

// Get challenge
export const getChallengeById = challengeId => async dispatch => {
  dispatch(setChallengeLoading());

  try {
    const res = await axios.get(`/api/challenges/${challengeId}`);

    if (res.data.success) {
      await dispatch(setChallengeData(res.data.challenge));
    } else {
      dispatch(createAlert(res.data.message, 'negative'));
    }
  } catch (error) {
    console.log(error);
    if (error) {
      const message = error.response.data.message || defaultErrorMessage;
      dispatch(createAlert(message, 'negative'));
    }
  }
};

// Clear challenge state
export const clearChallengeState = () => dispatch => {
  dispatch(clearState());
};

// Delete submission
export const deleteSubmissionById =
  (challengeId, responseId) => async dispatch => {
    dispatch(setChallengeLoading());

    try {
      const res = await axios.delete(
        `/api/challenges/${challengeId}/responses/${responseId}`
      );

      if (res.data.success) {
        dispatch(createAlert('Submission removed', 'positive'));
        const responses = res.data.responses;
        await dispatch(setChallengeResponses(responses));

        return true;
      } else {
        dispatch(createAlert(res.data.message, 'negative'));
        return false;
      }
    } catch (error) {
      if (error) {
        dispatch(createAlert(defaultErrorMessage, 'negative'));
      }

      return false;
    }
  };

// Add comment
export const addComment = (challengeId, text) => async dispatch => {
  dispatch(setChallengeLoading());

  try {
    const commentData = {
      text
    };

    const body = JSON.stringify({ commentData });
    const res = await axios.post(
      `/api/challenges/${challengeId}/comments`,
      body,
      config
    );

    if (res.data.success) {
      await dispatch(createAlert('Comment created', 'positive'));
      const comments = res.data.comments;
      await dispatch(updateComments(comments));
    } else {
      dispatch(createAlert(res.data.message, 'negative'));
    }
  } catch (error) {
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }
  }
};

// Edit comment
export const editComment = (challengeId, commentId, text) => async dispatch => {
  dispatch(setChallengeLoading());

  const commentData = {
    text
  };

  const body = JSON.stringify({ commentData });

  try {
    const res = await axios.put(
      `/api/challenges/${challengeId}/comments/${commentId}`,
      body,
      config
    );

    if (res.data.success) {
      await dispatch(createAlert('Comment updated', 'positive'));
      const comments = res.data.comments;
      await dispatch(updateComments(comments));
    } else {
      dispatch(createAlert(res.data.message, 'negative'));
    }
  } catch (error) {
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }
  }
};

// Delete comment
export const deleteCommentById = (challengeId, commentId) => async dispatch => {
  dispatch(setChallengeLoading());

  try {
    const res = await axios.delete(
      `/api/challenges/${challengeId}/comments/${commentId}`
    );

    if (res.data.success) {
      dispatch(createAlert('Comment removed', 'positive'));
      const comments = res.data.comments;
      await dispatch(updateComments(comments));
      return true;
    } else {
      dispatch(createAlert(res.data.message, 'negative'));
      return false;
    }
  } catch (error) {
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }

    return false;
  }
};

// Add reply to comment
export const addReply = (challengeId, commentId, text) => async dispatch => {
  dispatch(setChallengeLoading());

  const commentData = {
    text
  };

  const body = JSON.stringify({ commentData });

  try {
    const res = await axios.post(
      `/api/challenges/${challengeId}/comments/${commentId}/reply`,
      body,
      config
    );

    if (res.data.success) {
      await dispatch(createAlert('Reply created', 'positive'));
      const comments = res.data.comments;
      await dispatch(updateComments(comments));
    } else {
      dispatch(createAlert(res.data.message, 'negative'));
    }
  } catch (error) {
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }
  }
};

// Edit reply
// export const editReply =
//   (challengeId, commentId, replyId, text) => async dispatch => {
//     dispatch(setChallengeLoading());

//     const commentData = {
//       text
//     };

//     const body = JSON.stringify({ commentData });

//     try {
//       const res = await axios.put(
//         `/api/challenges/${challengeId}/comments/${commentId}/reply/${replyId}`,
//         body,
//         config
//       );

//       if (res.data.success) {
//         await dispatch(createAlert('Reply updated', 'positive'));
//         const comments = res.data.comments;
//         await dispatch(updateComments(comments));
//       } else {
//         dispatch(createAlert(res.data.message, 'negative'));
//       }
//     } catch (error) {
//       if (error) {
//         dispatch(createAlert(defaultErrorMessage, 'negative'));
//       }
//     }
//   };

// Delete reply
export const deleteReplyById =
  (challengeId, commentId, replyId) => async dispatch => {
    dispatch(setChallengeLoading());

    try {
      const res = await axios.delete(
        `/api/challenges/${challengeId}/comments/${commentId}/reply/${replyId}`
      );

      if (res.data.success) {
        dispatch(createAlert('Reply removed', 'positive'));
        const comments = res.data.comments;
        await dispatch(updateComments(comments));
        return true;
      } else {
        dispatch(createAlert(res.data.message, 'negative'));
        return false;
      }
    } catch (error) {
      if (error) {
        dispatch(createAlert(defaultErrorMessage, 'negative'));
      }

      return false;
    }
  };
