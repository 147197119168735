import { SET_LOADING, SET_NOTIFICATIONS } from './types';

export const notificationsInitialState = {
  notifications: [],
  isLoading: false
};

const reducer = (state = notificationsInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload
      };

    case SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };

    default:
      return state;
  }
};

export default reducer;
