import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Loader } from 'semantic-ui-react';

import PostList from './PostList';
import PostFilter from './PostFilter';

import { postsOps } from '../../redux/posts';

import { getLocalStorageItem } from '../../util/local-storage';
import { filterPostGroupsWithUserIds } from '../../util/post-filter';

const PostFeed = () => {
  const dispatch = useDispatch();

  const postGroups = useSelector(state => state.posts.postGroups);
  const isLoading = useSelector(state => state.posts.isLoading);
  const isMore = useSelector(state => state.posts.more);
  const following = useSelector(state => state.user.currentUser.following);
  const followers = useSelector(state => state.user.currentUser.followers);

  const targetRef = useRef();

  const postFeedFilter = getLocalStorageItem('postFeedFilter');

  const [filter, setFilter] = useState(
    postFeedFilter ? postFeedFilter : 'none'
  );

  function filterPostGroups() {
    return !filter || filter === 'none'
      ? postGroups
      : filter === 'following'
      ? filterPostGroupsWithUserIds(postGroups, following)
      : filter === 'followers'
      ? filterPostGroupsWithUserIds(postGroups, followers)
      : postGroups;
  }
  const filteredPostGroups = filterPostGroups();

  useEffect(() => {
    dispatch(postsOps.getPostGroups());
  }, [dispatch]);

  // infinite scroll fetch
  useEffect(() => {
    const observerOptions = {
      root: null, // Page as root
      rootMargin: '0px',
      threshold: 0.1
    };

    const handleObserver = async entries => {
      const feedEnd = entries[0];
      // console.log('feedEnd.isIntersecting: ', feedEnd.isIntersecting);

      if (feedEnd.isIntersecting && !isLoading && isMore) {
        dispatch(postsOps.getPostGroups());
      }
    };

    const observer = new IntersectionObserver(handleObserver, observerOptions);

    const el = targetRef && targetRef.current;

    if (!el) {
      return;
    }

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  }, [dispatch, isLoading, isMore, targetRef]);

  return (
    <>
      <div className='posts-container content-wrapper'>
        <PostFilter filter={filter} setFilter={setFilter} />
        <div className='post-feed'>
          <Loader size='huge' active={isLoading} />

          {postGroups.length > 0 && (
            <PostList postGroups={filteredPostGroups} />
          )}

          <div ref={targetRef} className='post-feed__end'></div>
        </div>
      </div>
    </>
  );
};

export default PostFeed;
