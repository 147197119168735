import {
  SET_CHALLENGE_LOADING,
  SET_CHALLENGE_DATA,
  SET_CHALLENGE_RESPONSES,
  CLEAR_STATE,
  UPDATE_COMMENTS
} from './types';

export const setChallengeLoading = () => ({
  type: SET_CHALLENGE_LOADING
});

export const setChallengeData = data => ({
  type: SET_CHALLENGE_DATA,
  payload: data
});

export const setChallengeResponses = responses => ({
  type: SET_CHALLENGE_RESPONSES,
  payload: responses
});

export const clearState = () => ({
  type: CLEAR_STATE
});

export const updateComments = comments => ({
  type: UPDATE_COMMENTS,
  payload: comments
});
