import {
  ADD_MESSAGE,
  ADD_MESSAGES,
  SET_MESSAGES,
  CLEAR_MESSAGES,
  SET_USERS
} from './types';

export const chatInitialState = {
  messages: [],
  users: []
};

const reducer = (state = chatInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, payload]
      };

    case ADD_MESSAGES:
      return {
        ...state,
        messages: [...state.messages, ...payload]
      };

    case SET_MESSAGES:
      return {
        ...state,
        messages: payload
      };

    case CLEAR_MESSAGES:
      return {
        ...state,
        messages: []
      };

    case SET_USERS:
      return {
        ...state,
        users: payload
      };

    default:
      return state;
  }
};

export default reducer;
