import { SET_THIS_YEAR_TOTAL, SET_LIFETIME_TOTAL } from './types';

export const setThisYearTotal = total => ({
  type: SET_THIS_YEAR_TOTAL,
  payload: total
});

export const setLifetimeTotal = total => ({
  type: SET_LIFETIME_TOTAL,
  payload: total
});
