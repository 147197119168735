import {
  SET_IMAGE_FILE,
  SET_IMAGE_URL,
  SET_PREVIEW_IMAGE,
  SET_CONTENT,
  SET_UPLOADING,
  CLEAR_PHOTO,
  CLEAR_FORM_STATE,
  LOAD_FORM_STATE
} from './types';

export const setImageFile = file => ({
  type: SET_IMAGE_FILE,
  payload: file
});

export const setPreviewImage = (id, url) => ({
  type: SET_PREVIEW_IMAGE,
  payload: {
    id,
    url
  }
});

export const setImageURL = imageURL => ({
  type: SET_IMAGE_URL,
  payload: imageURL
});

export const setContent = name => ({
  type: SET_CONTENT,
  payload: name
});

export const setUploading = status => ({
  type: SET_UPLOADING,
  payload: status
});

export const clearPhoto = () => ({
  type: CLEAR_PHOTO
});

export const clearFormState = () => ({
  type: CLEAR_FORM_STATE
});

export const loadFormState = submissionData => ({
  type: LOAD_FORM_STATE,
  payload: submissionData
});
