import {
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  SET_CURRENT_USER,
  UPDATE_CURRENT_USER,
  SET_LOADING,
  SET_XUMM_AUTH_URL
} from './types';

export const userInitialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: false,
  currentUser: {},
  loading: false,
  xummAuthURL: null
};

const reducer = (state = userInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
    case SIGN_IN_SUCCESS:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        token: payload.token,
        isAuthenticated: true,
        loading: false
      };

    case REGISTER_FAILURE:
    case SIGN_IN_FAILURE:
    case SIGN_OUT:
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        currentUser: {}
      };

    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        currentUser: payload
      };

    case UPDATE_CURRENT_USER:
      return {
        ...state,
        currentUser: payload
      };

    case SET_LOADING:
      return {
        ...state,
        loading: payload
      };

    case SET_XUMM_AUTH_URL:
      return {
        ...state,
        xummAuthURL: payload
      };

    default:
      return state;
  }
};

export default reducer;
