import {
  SET_IMAGE_FILE,
  SET_IMAGE_URL,
  SET_PREVIEW_IMAGE,
  SET_TITLE,
  SET_DESCRIPTION,
  SET_CONTENT,
  SET_TAGS,
  SET_IS_GATED,
  SET_GATE_FEE,
  SET_UPLOADING,
  SET_UPLOAD_SUCCESS,
  CLEAR_FORM_STATE,
  LOAD_FORM_STATE
} from './types';

export const lessonFormInitialState = {
  imageFile: null,
  imageURL: '',
  previewImage: {
    id: '',
    url: 'https://react.semantic-ui.com/images/wireframe/image.png'
  },
  title: '',
  description: '',
  content: '',
  tags: [],
  isTipGated: false,
  tipGateFee: 0,
  uploading: false,
  uploadSuccess: false,
  lessonId: '',
  dataLoaded: false
};

const reducer = (state = lessonFormInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_IMAGE_FILE:
      return {
        ...state,
        imageFile: payload
      };

    case SET_IMAGE_URL:
      return {
        ...state,
        imageURL: payload
      };

    case SET_PREVIEW_IMAGE:
      return {
        ...state,
        previewImage: payload
      };

    case SET_TITLE:
      return {
        ...state,
        title: payload
      };

    case SET_DESCRIPTION:
      return {
        ...state,
        description: payload
      };

    case SET_CONTENT:
      return {
        ...state,
        content: payload
      };

    case SET_TAGS:
      return {
        ...state,
        tags: payload
      };

    case SET_IS_GATED:
      return {
        ...state,
        isTipGated: payload
      };

    case SET_GATE_FEE:
      return {
        ...state,
        tipGateFee: payload
      };

    case SET_UPLOADING:
      return {
        ...state,
        uploading: payload
      };

    case SET_UPLOAD_SUCCESS:
      const { lessonId, uploadSuccess } = payload;
      return {
        ...state,
        lessonId,
        uploadSuccess,
        uploading: false
      };

    case CLEAR_FORM_STATE:
      return lessonFormInitialState;

    case LOAD_FORM_STATE:
      const {
        _id,
        photoURL,
        title,
        description,
        content,
        isTipGated,
        tipGateFee,
        tags
      } = payload;
      return {
        ...state,
        previewImage: {
          id: _id,
          url: photoURL
        },
        title,
        description,
        content,
        isTipGated,
        tipGateFee,
        tags,
        lessonId: _id,
        dataLoaded: true
      };

    default:
      return state;
  }
};

export default reducer;
