export const createMessageMap = messages => {
  const newMap = new Map();
  messages.forEach(message => {
    newMap.set(message._id, message);
  });

  return newMap;
};

export const mergeMessageMaps = (map1, map2) => new Map([...map1, ...map2]);

export const convertMessageMapToArray = map => [...map.values()];

export const mergeMessages = (messages1, messages2) => {
  const map1 = createMessageMap(messages1);
  const map2 = createMessageMap(messages2);
  const mergedMap = mergeMessageMaps(map1, map2);
  const mergedMessages = convertMessageMapToArray(mergedMap);

  return mergedMessages;
};

export const getTimestampId = () => new Date().getTime().toString();
