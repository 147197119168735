import {
  SET_IMAGE_FILE,
  SET_IMAGE_URL,
  SET_PREVIEW_IMAGE,
  SET_TITLE,
  SET_DESCRIPTION,
  SET_CONTENT,
  SET_TAGS,
  SET_IS_GATED,
  SET_GATE_FEE,
  SET_UPLOADING,
  SET_UPLOAD_SUCCESS,
  CLEAR_FORM_STATE,
  LOAD_FORM_STATE
} from './types';

export const setImageFile = file => ({
  type: SET_IMAGE_FILE,
  payload: file
});

export const setPreviewImage = (id, url) => ({
  type: SET_PREVIEW_IMAGE,
  payload: {
    id,
    url
  }
});

export const setTitle = text => ({
  type: SET_TITLE,
  payload: text
});

export const setDescription = text => ({
  type: SET_DESCRIPTION,
  payload: text
});

export const setContent = text => ({
  type: SET_CONTENT,
  payload: text
});

export const setTags = tags => ({
  type: SET_TAGS,
  payload: tags
});

export const setIsGated = status => ({
  type: SET_IS_GATED,
  payload: status
});

export const setGateFee = fee => ({
  type: SET_GATE_FEE,
  payload: fee
});

export const setImageURL = imageURL => ({
  type: SET_IMAGE_URL,
  payload: imageURL
});

export const setUploading = status => ({
  type: SET_UPLOADING,
  payload: status
});

export const setUploadSuccess = lessonId => ({
  type: SET_UPLOAD_SUCCESS,
  payload: {
    lessonId,
    uploadSuccess: true
  }
});

export const clearFormState = () => ({
  type: CLEAR_FORM_STATE
});

export const loadFormState = lessonData => ({
  type: LOAD_FORM_STATE,
  payload: lessonData
});
