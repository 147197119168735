export const SET_PROFILE_LOADING = 'SET_PROFILE_LOADING';
export const SET_PROFILE_NOT_FOUND = 'SET_PROFILE_NOT_FOUND';
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const SET_PROFILE_POSTS_LOADING = 'SET_PROFILE_POSTS_LOADING';
export const SET_PROFILE_POSTS = 'SET_PROFILE_POSTS';
export const CLEAR_STATE = 'CLEAR_STATE';
export const REMOVE_PROFILE_DATA_FOLLOWING_ITEM =
  'REMOVE_PROFILE_DATA_FOLLOWING_ITEM';
export const ADD_PROFILE_DATA_FOLLOWING_ITEM =
  'ADD_PROFILE_DATA_FOLLOWING_ITEM';
export const REMOVE_PROFILE_DATA_FOLLOWER_ITEM =
  'REMOVE_PROFILE_DATA_FOLLOWER_ITEM';
export const ADD_PROFILE_DATA_FOLLOWER_ITEM = 'ADD_PROFILE_DATA_FOLLOWER_ITEM';
