import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';

import { Icon, Item } from 'semantic-ui-react';

import VerifiedIcon from '../VerifiedIcon';
import LazyImage from '../LazyImage';

import { ReactComponent as DonateIcon } from '../../assets/donate.svg';
import { ReactComponent as ViewsIcon } from '../../assets/eye-con.svg';
import { ReactComponent as Logo } from '../../assets/XRP_Logo.svg';

import cloudinary from '../../services/cloudinary';
import { checkHasUserTipped } from '../../util/user';

const defaultProps = {
  id: '',
  postRank: 0,
  post: {
    photo: '',
    photoPublicId: '',
    author: '',
    authorDisplayName: '',
    authorVerified: false,
    description: '',
    viewsCount: 0,
    upvotes: [],
    upvoteCount: 0,
    commentCount: 0,
    postTipTotal: 0,
    isGated: false
  }
};

const PostItem = ({ id, postRank, post }) => {
  const userId = useSelector(state => state.user.currentUser._id);

  const {
    photo,
    photoPublicId,
    author,
    authorDisplayName,
    authorVerified,
    description,
    viewsCount,
    upvotes,
    upvoteCount,
    commentCount,
    postTipTotal,
    isGated
  } = post;

  const userIsAuthor = post.user === userId;

  const hasTipped = isGated ? checkHasUserTipped(upvotes, userId) : null;

  const isBlurred = !userIsAuthor && isGated && !hasTipped;

  const linkStyles = classNames({
    'post-item__link': true,
    'gated__photo-wrapper': isBlurred
  });

  const postData = {
    id,
    postRank,
    ...post
  };

  const headline =
    description.length > 66 ? (
      <span>{description.slice(0, 66)} &hellip;</span>
    ) : (
      description
    );

  const photoURL = photoPublicId
    ? `${cloudinary.config.base_url}/${cloudinary.types.FEED}/${photoPublicId}`
    : photo
    ? photo
    : 'https://react.semantic-ui.com/images/wireframe/image.png';

  return (
    <Item className='post-item'>
      <div className='post-item__rank'>
        <span>{postRank}</span>
      </div>

      <Link
        className={linkStyles}
        to={{ pathname: `/posts/${id}`, state: { postData } }}
      >
        <LazyImage src={photoURL} alt='post photo' />
      </Link>

      <Item.Content>
        <div className='post-item__header'>
          <Link
            className='post-item__header-author'
            to={{ pathname: `/posts/${id}`, state: { postData } }}
          >
            <span>{authorDisplayName ? authorDisplayName : `@${author}`}</span>
            <VerifiedIcon
              isVerified={authorVerified}
              viewerIsUser={false}
              iconSize={'small'}
              isVerifiedClass={'verified-primary'}
              notVerifiedClass={'verified-hidden'}
            />
          </Link>
        </div>

        <Item.Description className='post-item__description'>
          {headline}
        </Item.Description>
        <Item.Extra>
          <span className='post-item__views-count'>
            <ViewsIcon className='post-item__views-icon' />
            {viewsCount}
          </span>
          <span className='post-item__upvote-count'>
            <Icon name='heart outline' />
            {upvoteCount}
          </span>
          <span className='post-item__comment-count'>
            <Icon name='comments outline' />
            {commentCount}
          </span>
        </Item.Extra>
      </Item.Content>

      {isGated && (
        <DonateIcon
          className='post-item__gated-icon'
          title='A tip is required to see this post'
        />
      )}

      <Link to={`/posts/${id}/tip`} className='floated post-item__tips'>
        <Logo className='post-item__logo-xrp' />
        <div className='post-item__tips-amount'>{postTipTotal}</div>
      </Link>
    </Item>
  );
};

PostItem.defaultProps = defaultProps;

export default withRouter(PostItem);
