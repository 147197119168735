import { memo } from 'react';
import { useDispatch } from 'react-redux';

import { Popover, Transition } from '@headlessui/react';

import { notificationsOps } from '../../redux/notifications';

const MarkAllReadPopover = ({ count, isDisabled }) => {
  const dispatch = useDispatch();

  const { markAllAsRead } = notificationsOps;

  const handleClick = () => {
    dispatch(markAllAsRead());
  };

  return (
    <div className='px-4 py-3 border-b border-gray-200'>
      <span>Notifications</span>
      {count > 0 && <span>({count})</span>}

      <Popover className='relative float-right'>
        <Popover.Button disabled={isDisabled} className='outline-none'>
          <div className='text-gray-500 hover:text-gray-700'>
            <span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                fill='currentColor'
                className='w-6 h-6'
              >
                <path
                  fillRule='evenodd'
                  d='M10.5 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm0 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm0 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z'
                  clipRule='evenodd'
                />
              </svg>
            </span>
          </div>
        </Popover.Button>
        <Transition
          enter='transition duration-100 ease-out'
          enterFrom='transform scale-95 opacity-0'
          enterTo='transform scale-100 opacity-100'
          leave='transition duration-75 ease-out'
          leaveFrom='transform scale-100 opacity-100'
          leaveTo='transform scale-95 opacity-0'
        >
          <Popover.Panel className='absolute right-0 z-10 min-w-max min-h-max transform'>
            {({ close }) => (
              <button
                className='px-4 py-3 rounded text-white bg-[#028090]'
                onClick={() => {
                  handleClick();
                  close();
                }}
              >
                Mark All As Read
              </button>
            )}
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
};

export default memo(MarkAllReadPopover);
