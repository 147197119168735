export const SET_IMAGE_FILE = 'SET_IMAGE_FILE';
export const SET_IMAGE_URL = 'SET_IMAGE_URL';
export const SET_PREVIEW_IMAGE = 'SET_PREVIEW_IMAGE';
export const SET_DESCRIPTION = 'SET_DESCRIPTION';
export const SET_STORY = 'SET_STORY';
export const SET_IS_GATED = 'SET_IS_GATED';
export const SET_GATE_FEE = 'SET_GATE_FEE';
export const SET_UPLOADING = 'SET_UPLOADING';
export const SET_UPLOAD_SUCCESS = 'SET_UPLOAD_SUCCESS';
export const CLEAR_FORM_STATE = 'CLEAR_FORM_STATE';
export const LOAD_FORM_STATE = 'LOAD_FORM_STATE';
