export const getLocalStorageItem = localStorageName => {
  try {
    const localStorageItem = JSON.parse(localStorage.getItem(localStorageName));

    return localStorageItem ? localStorageItem : null;
  } catch (err) {
    console.log(err);
  }
};

export const setLocalStorageItem = (localStorageName, data) => {
  try {
    localStorage.setItem(localStorageName, JSON.stringify(data));
  } catch (err) {
    console.log(err);
  }
};
