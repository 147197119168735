import axios from 'axios';

import { alertOperations } from '../alert';
import { defaultErrorMessage } from '../alert/constants';
import {
  setLessonLoading,
  setLessonData,
  setSelectedLesson,
  setRedirectURL,
  clearState,
  updateComments
} from './actions';

const { createAlert } = alertOperations;

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

// Get lesson
export const getLessonById = lessonId => async dispatch => {
  dispatch(setLessonLoading());

  try {
    const res = await axios.get(`/api/lessons/${lessonId}`);

    if (res.data.success) {
      await dispatch(setSelectedLessonById(lessonId));
      await dispatch(setLessonData(res.data.lesson));
    } else {
      dispatch(createAlert(res.data.message, 'negative'));
    }
  } catch (error) {
    console.log(error);
    if (error) {
      const message = error.response.data.message || defaultErrorMessage;
      dispatch(createAlert(message, 'negative'));
    }
  }
};

// Clear lesson state
export const clearLessonState = () => dispatch => {
  dispatch(clearState());
};

// Add Tip
export const addTip = (lessonId, amount) => async dispatch => {
  try {
    if (!lessonId) {
      dispatch(
        createAlert(
          'Sorry, something went wrong... please try again later',
          'negative'
        )
      );

      return;
    }

    const tipData = {
      amount
    };

    const body = JSON.stringify({ tipData });

    const res = await axios.put(
      `/api/xumm/tip/lesson/${lessonId}`,
      body,
      config
    );

    const redirectURL = res.data.payloadURL;
    dispatch(setRedirectURL(redirectURL));
  } catch (error) {
    const errorMsg = error.message ? error.message : defaultErrorMessage;

    await dispatch(createAlert(errorMsg, 'negative'));
    // delay redirect because it kills alert prematurely
    setTimeout(() => dispatch(setRedirectURL(`/lessons/${lessonId}`)), 3000);
  }
};

// Set selected lesson
export const setSelectedLessonById = lessonId => dispatch => {
  dispatch(setSelectedLesson(lessonId));
};

// Add comment
export const addComment = (lessonId, text) => async dispatch => {
  dispatch(setLessonLoading());

  const commentData = {
    text
  };

  const body = JSON.stringify({ commentData });

  try {
    const res = await axios.post(
      `/api/lessons/${lessonId}/comments`,
      body,
      config
    );

    if (res.data.success) {
      await dispatch(createAlert('Comment created', 'positive'));
      const comments = res.data.comments;
      await dispatch(updateComments(comments));
    } else {
      dispatch(createAlert(res.data.message, 'negative'));
    }
  } catch (error) {
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }
  }
};

// Edit comment
export const editComment = (lessonId, commentId, text) => async dispatch => {
  dispatch(setLessonLoading());

  const commentData = {
    text
  };

  const body = JSON.stringify({ commentData });

  try {
    const res = await axios.put(
      `/api/lessons/${lessonId}/comments/${commentId}`,
      body,
      config
    );

    if (res.data.success) {
      await dispatch(createAlert('Comment updated', 'positive'));
      const comments = res.data.comments;
      await dispatch(updateComments(comments));
    } else {
      dispatch(createAlert(res.data.message, 'negative'));
    }
  } catch (error) {
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }
  }
};

// Delete comment
export const deleteCommentById = (lessonId, commentId) => async dispatch => {
  dispatch(setLessonLoading());

  try {
    const res = await axios.delete(
      `/api/lessons/${lessonId}/comments/${commentId}`
    );

    if (res.data.success) {
      dispatch(createAlert('Comment removed', 'positive'));
      const comments = res.data.comments;
      await dispatch(updateComments(comments));
      return true;
    } else {
      dispatch(createAlert(res.data.message, 'negative'));
      return false;
    }
  } catch (error) {
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }

    return false;
  }
};

// Add reply to comment
export const addReply = (lessonId, commentId, text) => async dispatch => {
  dispatch(setLessonLoading());

  const commentData = {
    text
  };

  const body = JSON.stringify({ commentData });

  try {
    const res = await axios.post(
      `/api/lessons/${lessonId}/comments/${commentId}/reply`,
      body,
      config
    );

    if (res.data.success) {
      await dispatch(createAlert('Reply created', 'positive'));
      const comments = res.data.comments;
      await dispatch(updateComments(comments));
    } else {
      dispatch(createAlert(res.data.message, 'negative'));
    }
  } catch (error) {
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }
  }
};

// Edit reply
export const editReply =
  (lessonId, commentId, replyId, text) => async dispatch => {
    dispatch(setLessonLoading());

    const commentData = {
      text
    };

    const body = JSON.stringify({ commentData });

    try {
      const res = await axios.put(
        `/api/lessons/${lessonId}/comments/${commentId}/reply/${replyId}`,
        body,
        config
      );

      if (res.data.success) {
        await dispatch(createAlert('Reply updated', 'positive'));
        const comments = res.data.comments;
        await dispatch(updateComments(comments));
      } else {
        dispatch(createAlert(res.data.message, 'negative'));
      }
    } catch (error) {
      if (error) {
        dispatch(createAlert(defaultErrorMessage, 'negative'));
      }
    }
  };

// Delete reply
export const deleteReplyById =
  (lessonId, commentId, replyId) => async dispatch => {
    dispatch(setLessonLoading());

    try {
      const res = await axios.delete(
        `/api/lessons/${lessonId}/comments/${commentId}/reply/${replyId}`
      );

      if (res.data.success) {
        dispatch(createAlert('Reply removed', 'positive'));
        const comments = res.data.comments;
        await dispatch(updateComments(comments));
        return true;
      } else {
        dispatch(createAlert(res.data.message, 'negative'));
        return false;
      }
    } catch (error) {
      if (error) {
        dispatch(createAlert(defaultErrorMessage, 'negative'));
      }

      return false;
    }
  };
