export const SET_POST_LOADING = 'SET_POST_LOADING';
export const SET_POST_NOT_FOUND = 'SET_POST_NOT_FOUND';
export const SET_POST_DATA = 'SET_POST_DATA';
export const SET_SELECTED_POST = 'SET_SELECTED_POST';
export const SET_ADJACENT_POST_IDS = 'SET_ADJACENT_POST_IDS';
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';
export const UPDATE_UPVOTES = 'UPDATE_UPVOTES';
export const CLEAR_STATE = 'CLEAR_STATE';
export const UPDATE_COMMENTS = 'UPDATE_COMMENTS';
export const DELETE_COMMENT = 'DELETE_COMMENT';
