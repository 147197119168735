import {
  ADD_LESSONS,
  DELETE_LESSON_BY_ID,
  SET_HASHTAGS,
  SET_LESSONS,
  SET_LESSONS_LOADING,
  SET_PARAMS,
  SET_TOTAL
} from './types';

export const lessonsInitialState = {
  list: [],
  hashtags: [{ name: 'all', count: null }],
  isLoading: false,
  params: {
    page: 1,
    perPage: 10,
    sort: 'createdAt',
    order: 'ASC',
    tag: '',
    search: '',
    fetched: false
  },
  total: 0
};

const removeLesson = (lessonId, lessons) => {
  const updatedLessons = lessons.filter(lesson => lesson._id !== lessonId);

  return updatedLessons;
};

const reducer = (state = lessonsInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_LESSONS:
      return {
        ...state,
        list: [...state.list, ...payload],
        params: {
          ...state.params,
          fetched: true
        }
      };

    case DELETE_LESSON_BY_ID:
      return {
        ...state,
        list: removeLesson(payload, state.list),
        isLoading: false
      };

    case SET_HASHTAGS:
      return {
        ...state,
        hashtags: payload
      };

    case SET_LESSONS:
      return {
        ...state,
        list: payload,
        params: {
          ...state.params,
          fetched: true
        }
      };

    case SET_LESSONS_LOADING:
      return {
        ...state,
        isLoading: payload
      };

    case SET_PARAMS:
      return {
        ...state,
        params: payload
      };

    case SET_TOTAL:
      return {
        ...state,
        total: payload
      };

    default:
      return state;
  }
};

export default reducer;
