import { getFormattedDate, dateIsBefore } from './time';

/**
 * @desc get date only from date string
 * @param {string} date
 * @return {string} dateOnly
 */
export const getDateOnly = dateString => {
  // convert UTC to local
  const localDateString = getFormattedDate(dateString);
  // get date only
  const dateOnly = localDateString.split('T')[0];
  // return result
  return dateOnly;
};

/**
 * @desc get group with matching date
 * @param {string} post date
 * @param {array} array of postGroups
 * @return {object} postGroup
 */
export const getGroupByDate = (date, groups) => {
  // comparison function
  const isMatchingDate = group => group.groupDate === date;

  // find post group with matching date
  const postGroup = groups.filter(isMatchingDate);

  // return result
  return postGroup;
};

/**
 * @desc get index of group with matching date
 * @param {string} post date
 * @param {array} array of postGroups
 * @return {number} index of matching group
 */
export const getGroupIndexByDate = (date, groups) => {
  // comparison function
  const isMatchingDate = group => group.groupDate === date;

  // find post group with matching date
  const postGroupIndex = groups.findIndex(isMatchingDate);
  // return result
  return postGroupIndex;
};

/**
 * @desc sort array of posts by method
 * @param {string} method
 * @param {array} postsArray
 * @return {array} sorted posts
 */
export const sortPostsByMethod = (method, postsArray) => {
  const compareFunction =
    method === 'date'
      ? (a, b) => dateIsBefore(a.date, b.date)
      : (a, b) => (a[method] - b[method]) * -1;

  return postsArray.sort(compareFunction);
};

/**
 * @desc find index
 * @param {string} sortMethod
 * @param {array} postsArray
 * @return {array} sorted posts
 */
export const findIndexById = (postId, postsArray) => {
  const compareFunction = post => post._id === postId;

  const postIndex = postsArray.findIndex(compareFunction);

  return postIndex;
};

/**
 * @desc find adjacent post IDs in post group
 * @param {string} postId
 * @param {array} postGroup
 * @return {object} {previous, next}
 */
export const findAdjacentPostIds = (postId, postGroup) => {
  const sortedPosts = sortPostsByMethod(
    postGroup.selectedSort,
    postGroup.groupPosts
  );

  const postIndex = findIndexById(postId, sortedPosts);

  const previous = postIndex > 0 ? sortedPosts[postIndex - 1]._id : null;

  const next =
    postIndex < sortedPosts.length - 1 ? sortedPosts[postIndex + 1]._id : null;

  return { previous, next };
};

/**
 * @desc get default post IDs from most recent post group
 * @param {array} postGroup
 * @return {object} {previous, next}
 */
export const getDefaultAdjacentPostIds = postGroup => {
  const sortedPosts = sortPostsByMethod(
    postGroup.selectedSort,
    postGroup.groupPosts
  );

  const defaultId = sortedPosts[0]._id;

  return { previous: defaultId, next: defaultId };
};
