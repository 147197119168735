import {
  SET_PROFILE_LOADING,
  SET_PROFILE_NOT_FOUND,
  SET_PROFILE_DATA,
  SET_PROFILE_POSTS_LOADING,
  SET_PROFILE_POSTS,
  CLEAR_STATE,
  REMOVE_PROFILE_DATA_FOLLOWING_ITEM,
  ADD_PROFILE_DATA_FOLLOWING_ITEM,
  REMOVE_PROFILE_DATA_FOLLOWER_ITEM,
  ADD_PROFILE_DATA_FOLLOWER_ITEM
} from './types';

export const setProfileLoading = () => ({
  type: SET_PROFILE_LOADING
});

export const setProfileNotFound = () => ({
  type: SET_PROFILE_NOT_FOUND
});

export const setProfileData = profile => ({
  type: SET_PROFILE_DATA,
  payload: profile
});

export const setProfilePostsLoading = loadingStatus => ({
  type: SET_PROFILE_POSTS_LOADING,
  payload: loadingStatus
});

export const setProfilePosts = posts => ({
  type: SET_PROFILE_POSTS,
  payload: posts
});

export const clearState = () => ({
  type: CLEAR_STATE
});

export const removeProfileDataFollowingItem = itemId => ({
  type: REMOVE_PROFILE_DATA_FOLLOWING_ITEM,
  payload: itemId
});

export const addProfileDataFollowingItem = item => ({
  type: ADD_PROFILE_DATA_FOLLOWING_ITEM,
  payload: item
});

export const removeProfileDataFollowerItem = itemId => ({
  type: REMOVE_PROFILE_DATA_FOLLOWER_ITEM,
  payload: itemId
});

export const addProfileDataFollowerItem = item => ({
  type: ADD_PROFILE_DATA_FOLLOWER_ITEM,
  payload: item
});
