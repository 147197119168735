export const defaultGallery = [
  'pics/62e9355ef8d1bb00166dc6b7/44334dcf-06eb-4216-a963-837021e40c3b',
  'pics/629ff88a84b106001676ccae/55e9285d-ef79-41af-acf2-d3544d564838',
  'pics/5f7d0058f3f80a0017864a29/093678fe-d49a-43c2-a6b0-d0da49c25860',
  'pics/5edadb7f6c3b550017ae3abb/adeb337e-b1a4-4ec6-8b44-16feb31a71eb',
  'pics/602b13965170640017c6fd47/e328a396-6638-4821-8a5b-d6e4428b49b7',
  'pics/5ec82d2a02f42b0017927c8d/yr3tnghkwfyexipbg7r3',
  'pics/5ecaafb5cbe9a1001746c44e/odgljbatki6wyfvxpb6y',
  'pics/5ed29fdaf79c0a0017f9e7ca/n0tepjfztwydjq1lig3a',
  'pics/62c49576a296540016feffa1/6fa1f742-5307-42e0-91fe-000e86dc57cd'
];
