import axios from 'axios';

import { alertOperations } from '../alert';
import { defaultErrorMessage } from '../alert/constants';

import {
  setProfileLoading,
  setProfileNotFound,
  setProfileData,
  setProfilePostsLoading,
  setProfilePosts,
  clearState
} from './actions';

const { createAlert } = alertOperations;

// Get profile with user ID
export const getProfileByUserId = userId => async (dispatch, getState) => {
  const { profile } = getState();

  if (profile.isProfileLoading) return;

  dispatch(setProfileLoading());

  try {
    const res = await axios.get(`/api/users/id/${userId}`);
    await dispatch(setProfileData(res.data));
    return;
  } catch (error) {
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }

    dispatch(setProfileNotFound(false));

    return;
  }
};

// Get profile
export const getProfileByUsername = username => async (dispatch, getState) => {
  const { profile } = getState();

  if (profile.isProfileLoading) return;

  dispatch(setProfileLoading());

  try {
    const res = await axios.get(`/api/users/${username}`);

    await dispatch(setProfileData(res.data));

    return { success: true };
  } catch (error) {
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }

    dispatch(setProfileNotFound(false));

    return;
  }
};

// Get profile posts by batch
export const getProfilePostsBatch = () => async (dispatch, getState) => {
  const { profile } = getState();
  const {
    profileData: { postIds },
    arePostsLoading,
    cursor
  } = profile;

  if (arePostsLoading) return;
  dispatch(setProfilePostsLoading(true));

  const batchNumber =
    window.innerWidth < 499
      ? 4
      : window.innerWidth < 651
      ? 6
      : window.innerWidth < 720
      ? 9
      : 12;

  try {
    // generate batch array
    // get index of next postId after cursor
    const nextPostIndex = cursor
      ? postIds.findIndex(postId => postId === cursor) + 1
      : 0;

    // create array of post IDs to request
    const batch = postIds.slice(nextPostIndex, nextPostIndex + batchNumber);

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = JSON.stringify({ batch });
    // make request for posts using batch array
    const res = await axios.post('/api/posts/batch', body, config);
    const { posts } = res.data;

    // set data
    await dispatch(setProfilePosts(posts));
  } catch (error) {
    dispatch(setProfilePostsLoading(false));
  }
};

// Clear Profile data
export const clearProfileData = () => dispatch => {
  dispatch(setProfileData({}));
};

// Clear Profile state
export const clearProfileState = () => dispatch => {
  dispatch(clearState());
};
