import {
  SET_IMAGE_FILE,
  SET_IMAGE_URL,
  SET_PREVIEW_IMAGE,
  SET_DESCRIPTION,
  SET_STORY,
  SET_IS_GATED,
  SET_GATE_FEE,
  SET_UPLOADING,
  SET_UPLOAD_SUCCESS,
  CLEAR_FORM_STATE,
  LOAD_FORM_STATE
} from './types';

export const setImageFile = file => ({
  type: SET_IMAGE_FILE,
  payload: file
});

export const setPreviewImage = (id, url) => ({
  type: SET_PREVIEW_IMAGE,
  payload: {
    id,
    url
  }
});

export const setDescription = text => ({
  type: SET_DESCRIPTION,
  payload: text
});

export const setStory = text => ({
  type: SET_STORY,
  payload: text
});

export const setIsGated = status => ({
  type: SET_IS_GATED,
  payload: status
});

export const setGateFee = fee => ({
  type: SET_GATE_FEE,
  payload: fee
});

export const setImageURL = imageURL => ({
  type: SET_IMAGE_URL,
  payload: imageURL
});

export const setUploading = status => ({
  type: SET_UPLOADING,
  payload: status
});

export const setUploadSuccess = postId => ({
  type: SET_UPLOAD_SUCCESS,
  payload: {
    postId,
    uploadSuccess: true
  }
});

export const clearFormState = () => ({
  type: CLEAR_FORM_STATE
});

export const loadFormState = postData => ({
  type: LOAD_FORM_STATE,
  payload: postData
});
