import { SET_THIS_YEAR_TOTAL, SET_LIFETIME_TOTAL } from './types';

export const statsInitialState = {
  thisYearName: new Date().getFullYear(),
  thisYearTotal: 0,
  lifetimeName: 'Since June 1, 2020',
  lifetimeTotal: 0
};

const reducer = (state = statsInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_THIS_YEAR_TOTAL:
      return {
        ...state,
        thisYearTotal: payload
      };

    case SET_LIFETIME_TOTAL:
      return {
        ...state,
        lifetimeTotal: payload
      };

    default:
      return state;
  }
};

export default reducer;
