import {
  SET_POST_LOADING,
  SET_POST_NOT_FOUND,
  SET_POST_DATA,
  SET_SELECTED_POST,
  SET_ADJACENT_POST_IDS,
  SET_REDIRECT_URL,
  UPDATE_UPVOTES,
  CLEAR_STATE,
  UPDATE_COMMENTS,
  DELETE_COMMENT
} from './types';

export const setPostLoading = status => ({
  type: SET_POST_LOADING,
  payload: status
});

export const setPostNotFound = status => ({
  type: SET_POST_NOT_FOUND,
  payload: status
});

export const setPostData = post => ({
  type: SET_POST_DATA,
  payload: post
});

export const setSelectedPost = id => ({
  type: SET_SELECTED_POST,
  payload: id
});

export const setAdjacentPostIds = adjacentPostIds => ({
  type: SET_ADJACENT_POST_IDS,
  payload: adjacentPostIds
});

export const setRedirectURL = url => ({
  type: SET_REDIRECT_URL,
  payload: url
});

export const updateVotes = upvote => ({
  type: UPDATE_UPVOTES,
  payload: upvote
});

export const clearState = () => ({
  type: CLEAR_STATE
});

export const updateComments = comments => ({
  type: UPDATE_COMMENTS,
  payload: comments
});

export const deleteComment = id => ({
  type: DELETE_COMMENT,
  payload: id
});
