import { memo } from 'react';
import { BellIcon } from '@heroicons/react/solid';

const NotificationsBell = ({ showUnread }) => {
  return (
    <div className='h-10 w-10 rounded-full flex items-center justify-center border border-gray-300 hover:border-[#028090]'>
      <BellIcon
        className='z-8 h-6 w-6 text-[#028090] cursor-pointer'
        // onClick={togglePanel}
      />
      {showUnread && (
        <span className='absolute top-0 right-0 flex h-3 w-3'>
          <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75'></span>
          <span className='relative inline-flex rounded-full h-3 w-3 bg-sky-500'></span>
        </span>
      )}
    </div>
  );
};

export default memo(NotificationsBell);
