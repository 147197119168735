import axios from 'axios';

import { setThisYearTotal, setLifetimeTotal } from './actions';

// Get stats
export const getStats = () => async dispatch => {
  try {
    const { data } = await axios.get('/api/stats');

    const { thisYearTotal, lifetimeTotal } = data.stats;

    await dispatch(setThisYearTotal(thisYearTotal));
    await dispatch(setLifetimeTotal(lifetimeTotal));
  } catch (error) {
    console.log('error: ', error);
  }
};
