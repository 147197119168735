import axios from 'axios';

import { setIsLoading, setNotifications } from './actions';

export const fetchNotifications = () => async (dispatch, getState) => {
  try {
    const {
      user: { currentUser },
      notifications: { isLoading }
    } = getState();

    if (!currentUser || isLoading) {
      console.log('isLoading - aborting');
      return;
    }

    await dispatch(setIsLoading(true));

    const { data } = await axios.get(
      `/api/notifications/user/${currentUser._id}`
    );

    await dispatch(setNotifications(data));
  } catch (error) {
    console.log('error: ', error);
  } finally {
    await dispatch(setIsLoading(false));
  }
};

export const markAsRead = id => async dispatch => {
  try {
    await axios.put(`/api/notifications/${id}/read`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    await dispatch(fetchNotifications());
  } catch (error) {
    console.log('error: ', error);
  }
};

export const markAllAsRead = () => async (dispatch, getState) => {
  try {
    const {
      notifications: { notifications }
    } = getState();

    if (notifications.length === 0) return;

    const hasUnread = notifications.some(
      notification => !notification.hasBeenRead
    );
    if (!hasUnread) return;

    const notificationIds = notifications.map(notification => notification._id);

    const body = JSON.stringify({ notificationIds });

    dispatch(setIsLoading(true));

    await axios.put(`/api/notifications/read`, body, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    await dispatch(fetchNotifications());
  } catch (error) {
    console.log('error: ', error);
  } finally {
    await dispatch(setIsLoading(false));
  }
};
