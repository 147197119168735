import axios from 'axios';

import { alertOperations } from '../alert';
import { defaultErrorMessage } from '../alert/constants';
import { profileActions } from '../profile';

import {
  setCurrentUser,
  registerSuccess,
  registerFailure,
  signInSuccess,
  signInFailure,
  signOut,
  updateCurrentUser,
  setLoading,
  setXummAuthURL
} from './actions';

import { setLocalStorageItem } from '../../util/local-storage';
import setAuthToken from '../../util/set-auth-token';

const { createAlert } = alertOperations;
const {
  removeProfileDataFollowingItem,
  addProfileDataFollowingItem,
  removeProfileDataFollowerItem,
  addProfileDataFollowerItem
} = profileActions;

const headers = {
  headers: {
    'Content-Type': 'application/json'
  }
};

// Set user
export const setUser = () => async dispatch => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get('/api/auth');

    if (!res.data) {
      dispatch(signOut());
      return;
    }

    await dispatch(setCurrentUser(res.data));
  } catch (error) {
    dispatch(signOut());
  }
};

// Register User
export const registerUser =
  ({ username, displayName, email, password }) =>
  async dispatch => {
    try {
      dispatch(setLoading(true));

      const body = JSON.stringify({
        username,
        displayName,
        email,
        password
      });

      const res = await axios.post('/api/users', body, headers);

      await dispatch(registerSuccess(res.data));

      await dispatch(setUser());

      await dispatch(createAlert('SUCCESS: You are registered', 'success'));

      return {};
    } catch (err) {
      const { error } = err.response.data;
      let errorMsg = error
        ? `Invalid ${error.param}: ${error.msg}`
        : defaultErrorMessage;

      await dispatch(createAlert(errorMsg, 'negative'));

      await dispatch(registerFailure());

      return { error };
    }
  };

// Signin User with email/password
export const signInUser = (email, password) => async dispatch => {
  dispatch(setLoading(true));

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post('/api/auth', body, headers);

    dispatch(signInSuccess(res.data));

    dispatch(setUser());

    return { success: true };
  } catch (error) {
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }

    dispatch(signInFailure());

    return { success: false };
  }
};

// Sign out
export const signOutUser = () => dispatch => {
  setLocalStorageItem('postFeedFilter', null);
  dispatch(signOut());
};

// Update user data
export const updateUser = data => async (dispatch, getState) => {
  try {
    const {
      user: { currentUser }
    } = getState();

    const body = JSON.stringify({ data });

    const res = await axios.put(`/api/users/${currentUser._id}`, body, headers);
    await dispatch(updateCurrentUser(res.data));

    await dispatch(createAlert('Account successfully updated', 'success'));

    return { success: true };
  } catch (err) {
    const { error } = err.response.data;
    let errorMsg = error
      ? `Invalid ${error.param}: ${error.msg}`
      : defaultErrorMessage;

    dispatch(createAlert(errorMsg, 'negative'));

    return { error: true };
  }
};

// Delete user
export const deleteUser = password => async (dispatch, getState) => {
  const {
    user: { currentUser }
  } = getState();

  const data = JSON.stringify({ password });

  const config = {
    headers: {
      'Content-Type': 'application/json'
    },
    data
  };

  try {
    const res = await axios.delete(`/api/users/${currentUser._id}`, config);

    await dispatch(signOut());

    dispatch(createAlert(res.data.msg, 'success'));

    return { success: true };
  } catch (error) {
    if (error.response) {
      const errors = error.response.data.errors;
      errors.forEach(error => dispatch(createAlert(error.msg, 'negative')));
    } else {
      dispatch(createAlert('Sorry, something went wrong', 'negative'));
    }

    return { success: false };
  }
};

// Update password
export const updatePassword =
  (password, newPassword) => async (dispatch, getState) => {
    const {
      user: { currentUser }
    } = getState();

    const body = JSON.stringify({ password, newPassword });

    try {
      const res = await axios.put(
        `/api/users/${currentUser._id}/password`,
        body,
        headers
      );

      dispatch(createAlert(res.data.msg, 'success'));

      return { success: true };
    } catch (error) {
      if (error.response) {
        const errors = error.response.data.errors;
        errors.forEach(error => dispatch(createAlert(error.msg, 'negative')));
      } else {
        dispatch(createAlert(defaultErrorMessage, 'negative'));
      }

      return { success: false };
    }
  };

// Request password reset
export const requestPasswordReset = email => async dispatch => {
  dispatch(setLoading(true));

  try {
    const body = JSON.stringify({ email });
    const res = await axios.post(
      '/api/auth/password-reset/request',
      body,
      headers
    );

    dispatch(createAlert(res.data.message, 'success'));
    dispatch(setLoading(false));

    return { success: true };
  } catch ({ response }) {
    dispatch(setLoading(false));
    console.log('error response', response);
    const message = response.data.message
      ? response.data.message
      : defaultErrorMessage;

    dispatch(createAlert(message, 'negative'));

    return { success: false };
  }
};

// Reset password
export const passwordReset =
  (email, password, connectionToken) => async dispatch => {
    dispatch(setLoading(true));

    try {
      const body = JSON.stringify({ email, password, connectionToken });
      const res = await axios.post('/api/auth/password-reset', body, headers);

      dispatch(createAlert('Password updated', 'success'));
      dispatch(setLoading(false));
      dispatch(signInSuccess(res.data));
      dispatch(setUser());

      return { success: true };
    } catch ({ response }) {
      dispatch(setLoading(false));
      console.log('error response', response);
      const message = response.data.message
        ? response.data.message
        : defaultErrorMessage;

      dispatch(createAlert(message, 'negative'));
      return { success: false };
    }
  };

// Disconnect from Xumm
export const disconnectUserFromXumm = () => async dispatch => {
  dispatch(setLoading(true));

  try {
    const res = await axios({
      method: 'put',
      url: 'api/xumm/disconnect',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    console.log('xumm disconnect result: ', res.data);

    await dispatch(updateCurrentUser(res.data));
    dispatch(
      createAlert(
        'SUCCESS: Your account has been successfully updated',
        'success'
      )
    );

    return;
  } catch (error) {
    console.log('xumm connect error: ', error);

    dispatch(
      createAlert(
        'Sorry, account update failed - please try again or contact support',
        'negative'
      )
    );

    return;
  } finally {
    dispatch(setLoading(false));
  }
};

// Authenticate with Xumm
export const authenticateWithXumm = () => async dispatch => {
  dispatch(setLoading(true));

  try {
    const res = await axios.get('api/xumm/signin', headers);
    const { payloadURL } = res.data;

    dispatch(setXummAuthURL(payloadURL));

    dispatch(setLoading(false));
  } catch (error) {
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }

    dispatch(setLoading(false));
  }
};

// Sign in user with xumm
export const signInUserWithXumm = txHex => async dispatch => {
  dispatch(setLoading(true));

  const body = JSON.stringify({ txHex });

  try {
    const res = await axios.post('api/xumm/signin', body, headers);
    const { token } = res.data;

    // set token local storage
    dispatch(signInSuccess({ token }));

    // get user with token; set info in redux
    dispatch(setUser());

    return { redirectPath: '/' };
  } catch (error) {
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }

    dispatch(signInFailure());

    return { redirectPath: '/signin' };
  }
};

// Follow a user
export const followUser = data => async (dispatch, getState) => {
  try {
    const {
      user: { loading }
    } = getState();

    if (loading) {
      console.log('already loading');
      return;
    } else {
      dispatch(setLoading(true));
    }

    const { targetId, viewerId, profileUserId } = data;

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.put(`/api/users/${targetId}/follow`, config);
    const { updatedUser, targetFollowItem } = res.data;

    await dispatch(updateCurrentUser(updatedUser));

    if (viewerId === profileUserId) {
      // add target to profileData.following
      await dispatch(addProfileDataFollowingItem(targetFollowItem));
    } else if (targetId === profileUserId) {
      // add viewer to profileData.followers
      const newFollower = {
        _id: updatedUser._id,
        username: updatedUser.username,
        avatar: updatedUser.avatar
      };
      await dispatch(addProfileDataFollowerItem(newFollower));
    }

    await dispatch(createAlert('Follow successful', 'success'));
    await dispatch(setLoading(false));
    return;
  } catch (error) {
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }

    dispatch(setLoading(false));
    return;
  }
};

// Unfollow a user
export const unfollowUser = data => async (dispatch, getState) => {
  try {
    const {
      user: { loading }
    } = getState();

    if (loading) {
      return;
    } else {
      dispatch(setLoading(true));
    }

    const { targetId, viewerId, profileUserId } = data;

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.put(`/api/users/${targetId}/unfollow`, config);
    const { updatedUser } = res.data;

    await dispatch(updateCurrentUser(updatedUser));

    if (viewerId === profileUserId) {
      // remove target from profileData.following
      await dispatch(removeProfileDataFollowingItem(targetId));
    } else if (targetId === profileUserId) {
      // remove viewer from profileData.followers
      await dispatch(removeProfileDataFollowerItem(viewerId));
    }

    dispatch(createAlert('Unfollow successful', 'success'));
    dispatch(setLoading(false));

    return;
  } catch (error) {
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }

    dispatch(setLoading(false));

    return;
  }
};
