import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

const LazyImage = ({ src, alt }) => {
  const refPlaceholder = React.useRef();
  const refImage = React.useRef();

  // default height based on thumbnail size 80 x 80
  const [imgHeight, setImgHeight] = React.useState('80px');

  const calculateImgHeight = () => {
    const fileHeight = refImage.current.naturalHeight;
    const fileWidth = refImage.current.naturalWidth;
    // calculates thumbnail height based on width = 80px
    return fileHeight / (fileWidth / 80);
  };

  const removePlaceholder = () => {
    refPlaceholder.current.remove();
  };

  const handleImageOnLoad = () => {
    const height = calculateImgHeight();
    setImgHeight(`${height}px`);

    removePlaceholder();
  };

  return (
    <div style={{ height: imgHeight }} className='lazy-image__wrapper-thumb'>
      <div className='lazy-image__placeholder' ref={refPlaceholder} />
      <LazyLoad overflow once offset={200}>
        <img
          className='lazy-image__img'
          ref={refImage}
          onLoad={handleImageOnLoad}
          onError={removePlaceholder}
          src={src}
          alt={alt}
        />
      </LazyLoad>
    </div>
  );
};

LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default LazyImage;
