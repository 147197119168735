import {
  SET_CHALLENGE_LOADING,
  SET_CHALLENGE_DATA,
  SET_CHALLENGE_RESPONSES,
  CLEAR_STATE,
  UPDATE_COMMENTS
} from './types';

export const challengeInitialState = {
  challengeData: {},
  isLoading: false
};

const reducer = (state = challengeInitialState, action) => {
  const { type, payload } = action;
  const { challengeData } = state;

  switch (type) {
    case SET_CHALLENGE_LOADING:
      return {
        ...state,
        isLoading: true
      };

    case SET_CHALLENGE_DATA:
      return {
        ...state,
        challengeData: payload,
        isLoading: false
      };

    case SET_CHALLENGE_RESPONSES:
      return {
        ...state,
        challengeData: {
          ...challengeData,
          responses: payload
        },
        isLoading: false
      };

    case CLEAR_STATE:
      return challengeInitialState;

    case UPDATE_COMMENTS:
      return {
        ...state,
        challengeData: {
          ...challengeData,
          comments: payload
        },
        isLoading: false
      };

    default:
      return state;
  }
};

export default reducer;
