import axios from 'axios';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { defaultErrorMessage } from '../alert/constants';
import { alertOperations } from '../alert';
import {
  setImageFile,
  setImageURL,
  setPreviewImage,
  setContent,
  setUploading,
  clearPhoto,
  clearFormState,
  loadFormState
} from './actions';
import { challengeActions } from '../challenge';

import cloudinary from '../../services/cloudinary';

const { createAlert } = alertOperations;
const { setChallengeResponses } = challengeActions;

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

// set challenge form image file
export const setSubmissionImage = file => dispatch => {
  if (!file) {
    dispatch(
      createAlert(
        'Sorry, your image file is over 5MG or an unsupported type.',
        'negative'
      )
    );

    return;
  }

  try {
    const reader = new FileReader();
    reader.onload = function (e) {
      dispatch(setPreviewImage(_.uniqueId(), e.target.result));
    };

    reader.readAsDataURL(file);

    dispatch(setImageFile(file));
  } catch (error) {
    console.error(error);
    dispatch(
      createAlert(
        'Sorry, something went wrong. Please check your image file.',
        'negative'
      )
    );
  }
};

export const setSubmissionContent = content => dispatch => {
  dispatch(setContent(content));
};

export const uploadPhoto =
  ({ userId, imageFile }) =>
  async dispatch => {
    if (!userId || !imageFile) {
      return;
    }

    try {
      await dispatch(setUploading(true));

      const publicId = `${userId}/${uuidv4()}`;

      const instance = axios.create();
      delete instance.defaults.headers.common['x-auth-token'];

      const requestData = {
        file: imageFile,
        upload_preset: cloudinary.config.upload_preset,
        public_id: publicId
      };

      const res = await instance.post(
        cloudinary.config.upload_url,
        requestData
      );
      const { secure_url, public_id } = res.data;

      await dispatch(setImageURL(secure_url));

      await dispatch(createSubmission(public_id));
    } catch (error) {
      dispatch(
        createAlert(
          'Image upload failed - Please check your image file',
          'negative'
        )
      );
      await dispatch(setUploading(false));
    }
  };

export const createSubmission = photoPublicId => async (dispatch, getState) => {
  try {
    await dispatch(setUploading(true));

    const { chalSubmitForm, challenge } = getState();

    const responseData = {
      content: chalSubmitForm.content,
      photoPublicId: photoPublicId ? photoPublicId : ''
    };

    const body = JSON.stringify({ responseData });

    const res = await axios.post(
      `/api/challenges/${challenge.challengeData._id}/responses`,
      body,
      config
    );

    if (res.data.success) {
      await dispatch(createAlert('Submission created', 'positive'));
      const responses = res.data.responses;
      await dispatch(setChallengeResponses(responses));
    } else {
      dispatch(createAlert(res.data.message, 'negative'));
    }
  } catch (error) {
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }
  } finally {
    await dispatch(setUploading(false));
    await dispatch(clearFormState());
  }
};

// Edit submission
export const updateSubmission = () => async (dispatch, getState) => {
  try {
    await dispatch(setUploading(true));

    const { chalSubmitForm } = getState();

    const responseData = {
      content: chalSubmitForm.content
    };

    const body = JSON.stringify({ responseData });

    const res = await axios.put(
      `/api/challenges/${chalSubmitForm.challengeId}/responses/${chalSubmitForm.responseId}`,
      body,
      config
    );

    if (res.data.success) {
      await dispatch(createAlert('Submission updated', 'positive'));
      const responses = res.data.responses;
      await dispatch(setChallengeResponses(responses));
    } else {
      dispatch(createAlert(res.data.message, 'negative'));
    }
  } catch (error) {
    if (error) {
      dispatch(createAlert(defaultErrorMessage, 'negative'));
    }
  } finally {
    await dispatch(setUploading(false));
  }
};

export const clearSubmissionPhoto = () => dispatch => {
  dispatch(clearPhoto());
};

export const clearSubmissionForm = () => dispatch => dispatch(clearFormState());

export const loadSubmissionData = submissionData => dispatch =>
  dispatch(loadFormState(submissionData));
