const transformations = {
  FEED_THUMB: `t_feed_thumb`,
  PROFILE_THUMB: `t_profile_thumb`,
  FULL_SIZE: `t_full_size`,
  ASSET_ICON: `f_auto`,
  ASSET_IMG: `f_auto,q_auto`,
  ASSET_IMG_LARGE: `f_auto,q_auto,w_auto,c_scale`
};

const types = {
  FEED: transformations.FEED_THUMB,
  PROFILE: transformations.PROFILE_THUMB,
  POST: transformations.FULL_SIZE,
  ICON: transformations.ASSET_ICON,
  IMAGE: transformations.ASSET_IMG,
  IMAGE_LARGE: transformations.ASSET_IMG_LARGE
};

const config = {
  cloud_name: `dwlkh8tj3`,
  upload_url: `https://api.cloudinary.com/v1_1/dwlkh8tj3/image/upload`,
  upload_preset: `xhrw4toc`,
  base_url: `https://res.cloudinary.com/dwlkh8tj3/image/upload`
};

const assets = {
  arrow: `${config.base_url}/${types.ICON}/assets/img/Arrow-white.png`,
  coins: `${config.base_url}/${types.ICON}/assets/img/Coins-white.png`,
  cloud: `${config.base_url}/${types.ICON}/assets/img/Cloud-white.png`,
  gfamFormula: `${config.base_url}/${types.IMAGE_LARGE}/assets/img/gfam-logo-formula.png`,
  gfamIcon: `${config.base_url}/${types.ICON}/assets/img/Logo-white-small.png`,
  gfamLogo: `${config.base_url}/${types.IMAGE_LARGE}/assets/img/gfam-logo-teal-trans.png`,
  grantLogo: `${config.base_url}/${types.IMAGE_LARGE}/assets/img/grant-for-the-web.png`,
  mgLogo: `${config.base_url}/${types.ICON}/assets/img/mg-social-icon.png`,
  notFound: `${config.base_url}/${types.IMAGE_LARGE}/assets/img/404-error.jpg`,
  scarletbwild: `${config.base_url}/${types.IMAGE_LARGE}/assets/img/scarletbwild.png`
};

const cloudinary = {
  assets,
  config,
  transformations,
  types,
  t_asset_img: `f_auto,q_auto`,
  t_asset_img_lrg: `f_auto,q_auto,w_auto,c_scale`,
  t_asset_icon: `f_auto`
};

export default cloudinary;
