import axios from 'axios';

import { setPostingDisabled, setAdsEnabled, setAppSettings } from './actions';

// Get posting setting
export const checkPostingSetting = () => async dispatch => {
  try {
    const res = await axios.get('/api/settings/posting');

    await dispatch(setPostingDisabled(res.data.postingDisabled));
  } catch (error) {
    console.log('error: ', error);
  }
};

// Get banner ad status
export const checkAdsEnabled = () => async dispatch => {
  try {
    const res = await axios.get('/api/settings/ads');

    await dispatch(setAdsEnabled(res.data.adsEnabled));
  } catch (error) {
    console.log('error: ', error);
  }
};

// Get all status
export const checkAppSettings = () => async dispatch => {
  try {
    const res = await axios.get('/api/settings');

    let payload = {};

    res.data.forEach(item => (payload[item.setting] = item.settingValue));

    await dispatch(setAppSettings(payload));
  } catch (error) {
    console.log('error: ', error);
  }
};
