import _ from 'lodash';
import { setAlert, removeAlert } from './actions';

const createAlert = (msg, alertType, timeout = 5000) => dispatch => {
  const id = _.random(10000, 99999);
  dispatch(setAlert(msg, alertType, id));

  setTimeout(() => {
    dispatch(removeAlert(id));
  }, timeout);
};

export { createAlert };
