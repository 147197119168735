import React, { Component } from 'react';
import { connect } from 'react-redux';
import { array, bool, number, string } from 'prop-types';

import { Button, Item, Divider, Select } from 'semantic-ui-react';

import PostItem from '../PostItem';

import { postsOps } from '../../redux/posts';

import { dateIsBefore } from '../../util/time';

class PostGroupClass extends Component {
  state = {
    isShortlist: this.props.shortlist,
    displayPosts: this.props.groupPosts,
    sortMethod: this.props.groupTitle === 'Today' ? 'date' : 'commentCount'
  };

  static propTypes = {
    groupDate: string.isRequired,
    groupTitle: string.isRequired,
    groupCount: number.isRequired,
    groupTipTotal: number.isRequired,
    groupPosts: array,
    shortlist: bool,
    selectedSort: string
  };

  static defaultProps = {
    groupDate: '',
    groupTitle: '',
    groupCount: 0,
    groupTipTotal: 0,
    groupPosts: [],
    shortlist: false,
    selectedSort: ''
  };

  componentDidMount() {
    const { sortMethod, isShortlist } = this.state;

    this.setDisplayPosts(sortMethod, isShortlist);
  }

  componentDidUpdate(prevProps, prevState) {
    const { sortMethod, isShortlist } = this.state;

    if (
      sortMethod !== prevState.sortMethod ||
      isShortlist !== prevState.isShortlist ||
      this.props.groupPosts !== prevProps.groupPosts
    ) {
      this.setDisplayPosts(sortMethod, isShortlist);
    }
  }

  sortPosts = (posts, method) => {
    const newList = posts.slice();

    if (method === 'date') {
      newList.sort((a, b) => {
        return dateIsBefore(a.date, b.date) ? 1 : -1;
      });
    } else if (method === 'upvoteCount') {
      newList.sort((a, b) => {
        if (a.upvoteCount === b.upvoteCount) {
          if (a.postTipTotal === b.postTipTotal) {
            if (a.commentCount === b.commentCount) {
              if (a.viewsCount === b.viewsCount) {
                return dateIsBefore(a.date, b.date) ? 1 : -1;
              }

              return (a.viewsCount - b.viewsCount) * -1;
            }

            return (a.commentCount - b.commentCount) * -1;
          }

          return (a.postTipTotal - b.postTipTotal) * -1;
        }

        return (a.upvoteCount - b.upvoteCount) * -1;
      });
    } else {
      newList.sort((a, b) => {
        if (a[method] === b[method]) {
          if (a.commentCount === b.commentCount) {
            if (a.viewsCount === b.viewsCount) {
              if (a.postTipTotal === b.postTipTotal) {
                return dateIsBefore(a.date, b.date) ? 1 : -1;
              }

              return (a.postTipTotal - b.postTipTotal) * -1;
            }

            return (a.viewsCount - b.viewsCount) * -1;
          }

          return (a.commentCount - b.commentCount) * -1;
        }

        return (a[method] - b[method]) * -1;
      });
    }

    return newList;
  };

  getShortlist = posts => posts.slice(0, 10);

  setDisplayPosts = (method, short) => {
    const sorted = this.sortPosts(this.props.groupPosts, method);
    const list = short ? this.getShortlist(sorted) : sorted;

    this.setState(state => ({
      displayPosts: list
    }));
  };

  handleSelect = (e, { value }) => {
    this.setState(state => ({
      sortMethod: value
    }));

    this.props.setGroupSelectedSort(this.props.groupDate, value);
  };

  handleClick = () => {
    this.setState(state => ({
      isShortlist: false
    }));
  };

  render() {
    const { groupTitle, groupCount, groupTipTotal } = this.props;
    const { isShortlist, displayPosts, sortMethod } = this.state;

    const sortOptions = [
      { key: 1, value: 'viewsCount', text: 'Views' },
      { key: 2, value: 'postTipTotal', text: 'Tips Total' },
      { key: 3, value: 'upvoteCount', text: 'Tips' },
      { key: 4, value: 'commentCount', text: 'Comments' },
      { key: 5, value: 'date', text: 'Most Recent' }
    ];

    return (
      <div className='post-group'>
        <div className='post-group__header'>{groupTitle}</div>
        <div className='post-group__heading'>
          <div className='pl-4 leading-[18px] text-lg text-black/[0.6]'>
            <span>
              {groupCount} stories and {groupTipTotal} XRP tips
            </span>
            <span> were received</span>
          </div>
          <div className='post-group__sort'>
            <span>Sort by: </span>
            <Select
              options={sortOptions}
              value={sortMethod}
              icon='chevron down'
              onChange={this.handleSelect}
            />
          </div>
        </div>

        <Divider />

        <Item.Group divided unstackable>
          {displayPosts.map((post, idx) => {
            return (
              <PostItem
                key={`${post._id}${idx}`}
                id={post._id}
                postRank={idx + 1}
                post={post}
              />
            );
          })}
        </Item.Group>

        <Divider />

        {isShortlist && groupCount > 10 && (
          <div className='post-group__btn'>
            <Button basic content='Show All' onClick={this.handleClick} />
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setGroupSelectedSort: (groupDate, sortMethod) =>
    dispatch(postsOps.setGroupSelectedSort(groupDate, sortMethod))
});

export default connect(null, mapDispatchToProps)(PostGroupClass);
