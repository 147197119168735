import { SET_BLOG_DATA, SET_BLOG_LOADING, SET_BLOG_POST_DATA } from './types';

export const blogInitialState = {
  blogPosts: [],
  blogPostsSkip: 0,
  blogPostsTotal: null,
  blogPost: {},
  isLoading: false
};

const reducer = (state = blogInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_BLOG_DATA:
      const { items, skip, total } = payload;
      return {
        ...state,
        blogPosts: [...state.blogPosts, ...items],
        blogPostsSkip: skip + items.length,
        blogPostsTotal: total,
        isLoading: false
      };

    case SET_BLOG_POST_DATA:
      return {
        ...state,
        blogPost: payload,
        isLoading: false
      };

    case SET_BLOG_LOADING:
      return {
        ...state,
        isLoading: payload
      };

    default:
      return state;
  }
};

export default reducer;
