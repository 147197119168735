import {
  SET_PROFILE_LOADING,
  SET_PROFILE_NOT_FOUND,
  SET_PROFILE_DATA,
  SET_PROFILE_POSTS_LOADING,
  SET_PROFILE_POSTS,
  CLEAR_STATE,
  REMOVE_PROFILE_DATA_FOLLOWING_ITEM,
  ADD_PROFILE_DATA_FOLLOWING_ITEM,
  REMOVE_PROFILE_DATA_FOLLOWER_ITEM,
  ADD_PROFILE_DATA_FOLLOWER_ITEM
} from './types';

export const profileInitialState = {
  profileLoaded: false,
  isProfileLoading: false,
  profileNotFound: false,
  profileData: {
    userId: '',
    username: '',
    displayName: '',
    bio: '',
    social: {
      twitter: '',
      instagram: ''
    },
    userLinks: [],
    following: [],
    followers: [],
    followingCount: 0,
    followersCount: 0,
    avatar: '',
    postTotal: 0,
    viewsCount: 0,
    commentTotal: 0,
    tipsGivenTotal: 0,
    tipsReceived: 0,
    tipsReceivedTotal: 0,
    verified: false,
    postIds: [],
    location: '',
    dateJoined: '',
    milestones: [],
    streaks: {}
  },
  arePostsLoading: false,
  profilePosts: [],
  cursor: null,
  isMore: true
};

// profile post shape
// eslint-disable-next-line
const profilePostDefault = {
  postId: '', // ObjectId
  photo: '', // URL string
  photoPublicId: '', // cloudinary public_id string
  author: '', // username string
  authorDisplayName: '',
  description: '', // string
  date: '', // date string
  viewsCount: 0,
  upvoteCount: 0,
  commentCount: 0,
  postTipTotal: 0,
  isGated: false
};

const reducer = (state = profileInitialState, action) => {
  const { type, payload } = action;
  const { profileData, profilePosts, cursor } = state;
  let current, updated;

  function updateProfilePosts(newPosts) {
    //  update profile posts array
    const updatedProfilePosts = [...profilePosts, ...newPosts];
    //  update cursor
    const updatedCursor = newPosts[newPosts.length - 1].postId ?? cursor;
    //  determine if more undisplayed posts
    const updatedIsMore =
      profileData.postIds.length > updatedProfilePosts.length;

    return {
      updatedProfilePosts,
      updatedCursor,
      updatedIsMore
    };
  }

  switch (type) {
    case SET_PROFILE_LOADING:
      return {
        ...state,
        isProfileLoading: true
      };

    case SET_PROFILE_NOT_FOUND:
      return {
        ...state,
        isProfileLoading: false,
        profileNotFound: true
      };

    case SET_PROFILE_DATA:
      return {
        ...state,
        profileData: payload,
        profileLoaded: true,
        isProfileLoading: false
      };

    case SET_PROFILE_POSTS_LOADING:
      return {
        ...state,
        arePostsLoading: payload
      };

    case SET_PROFILE_POSTS:
      const { updatedProfilePosts, updatedCursor, updatedIsMore } =
        updateProfilePosts(payload);

      return {
        ...state,
        profilePosts: updatedProfilePosts,
        cursor: updatedCursor,
        isMore: updatedIsMore,
        arePostsLoading: false
      };
    case CLEAR_STATE:
      return profileInitialState;

    case REMOVE_PROFILE_DATA_FOLLOWING_ITEM:
      current = state.profileData.following;
      updated = current.filter(item => item._id !== payload);

      return {
        ...state,
        profileData: {
          ...state.profileData,
          following: updated,
          followingCount: updated.length
        }
      };

    case ADD_PROFILE_DATA_FOLLOWING_ITEM:
      const following = state.profileData.following;
      following.push(payload);

      return {
        ...state,
        profileData: {
          ...state.profileData,
          following: following,
          followingCount: following.length
        }
      };

    case REMOVE_PROFILE_DATA_FOLLOWER_ITEM:
      current = state.profileData.followers;
      updated = current.filter(item => item._id !== payload);

      return {
        ...state,
        profileData: {
          ...state.profileData,
          followers: updated,
          followersCount: updated.length
        }
      };

    case ADD_PROFILE_DATA_FOLLOWER_ITEM:
      const followers = state.profileData.followers;
      followers.push(payload);

      return {
        ...state,
        profileData: {
          ...state.profileData,
          followers: followers,
          followersCount: followers.length
        }
      };

    default:
      return state;
  }
};

export default reducer;
