import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Image } from 'semantic-ui-react';

import HeaderMenu from '../HeaderMenu';

import cloudinary from '../../services/cloudinary';

const HeaderWithLogon = () => {
  const gfamLogo = cloudinary.assets.gfamLogo;

  return (
    <div className='header__container'>
      <div className='header__wrapper'>
        <Link to='/' className='header__logo'>
          <Image src={gfamLogo} />
        </Link>
        <div className='header__actions'>
          <HeaderMenu />

          <Button
            as={Link}
            to={'/signin'}
            basic
            compact
            className='header__login-btn'
          >
            Sign In
          </Button>
          <Button
            as={Link}
            to={'/register'}
            compact
            className='header__register-btn'
          >
            Sign Up
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HeaderWithLogon;
