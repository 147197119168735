import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Popover, Transition } from '@headlessui/react';
import NotificationsButton from './NotificationsButton';
import NotificationsFeed from './NotificationsFeed';

import { notificationsOps } from '../../redux/notifications';

const UPDATE_INTERVAL_IN_MINUTES = 3;

const Notifications = () => {
  const dispatch = useDispatch();

  const userId = useSelector(state => state.user.currentUser._id);
  const notifications = useSelector(state => state.notifications.notifications);

  const { fetchNotifications } = notificationsOps;

  useEffect(() => {
    if (!userId) return;

    // initial fetch
    dispatch(fetchNotifications());

    // update fetch
    const updateInterval = setInterval(() => {
      dispatch(fetchNotifications());
    }, 1000 * 60 * UPDATE_INTERVAL_IN_MINUTES);

    return () => clearInterval(updateInterval);
  }, [dispatch, fetchNotifications, userId]);

  return (
    <Popover className='relative'>
      <Popover.Button className='outline-none'>
        <NotificationsButton
          showUnread={notifications.some(
            notification => !notification.hasBeenRead
          )}
        />
      </Popover.Button>

      <Transition
        enter='transition duration-100 ease-out'
        enterFrom='transform scale-95 opacity-0'
        enterTo='transform scale-100 opacity-100'
        leave='transition duration-75 ease-out'
        leaveFrom='transform scale-100 opacity-100'
        leaveTo='transform scale-95 opacity-0'
      >
        <NotificationsFeed notifications={notifications} />
      </Transition>
    </Popover>
  );
};

export default Notifications;
