import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import initialState from './initialState';
import rootReducer from './rootReducer';

const middleware = composeWithDevTools(applyMiddleware(thunk));

const store = createStore(rootReducer, initialState, middleware);

export default store;
