import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Loader } from 'semantic-ui-react';

import Alert from '../components/Alert';
import Header from '../components/Header';
import PostFeed from '../components/PostFeed';

const Account = lazy(() => import('../components/Account'));
const Blog = lazy(() => import('../components/Blog'));
const BlogPost = lazy(() => import('../components/BlogPost'));
const Chat = lazy(() => import('../components/Chat'));
const Challenge = lazy(() => import('../components/Challenge'));
const Challenges = lazy(() => import('../components/Challenges'));
const Landing = lazy(() => import('../components/Landing'));
const Leaderboards = lazy(() => import('../components/Leaderboards'));
const Lesson = lazy(() => import('../components/Lesson'));
const LessonEdit = lazy(() => import('../components/LessonEdit'));
const LessonForm = lazy(() => import('../components/LessonForm'));
const LessonList = lazy(() => import('../components/LessonList'));
const LessonTip = lazy(() => import('../components/LessonTip'));
const NotFound = lazy(() => import('../components/NotFound'));
const PasswordResetRequest = lazy(() =>
  import('../components/PasswordResetRequest')
);
const PasswordResetForm = lazy(() => import('../components/PasswordResetForm'));
const Post = lazy(() => import('../components/Post'));
const PostEdit = lazy(() => import('../components/PostEdit'));
const PostForm = lazy(() => import('../components/PostForm'));
const PostTip = lazy(() => import('../components/PostTip'));
const Profile = lazy(() => import('../components/Profile'));
const RegisterForm = lazy(() => import('../components/RegisterForm'));
const SignIn = lazy(() => import('../components/SignIn'));
const Support = lazy(() => import('../components/Support'));
const Welcome = lazy(() => import('../components/Welcome'));
const XummSignin = lazy(() => import('../components/XummSignin'));

const Routes = () => (
  <Switch>
    <Route exact path='/' component={PostFeed} />
    <Route exact path='/posts/new' component={PostForm} />
    <Route exact path='/posts/:id' component={Post} />
    <Route exact path='/posts/:id/edit' component={PostEdit} />
    <Route exact path='/posts/:id/tip' component={PostTip} />
    <Route path='/home' component={Landing} />
    <Route path='/leaderboards' component={Leaderboards} />
    <Route exact path='/learn' component={LessonList} />
    <Route exact path='/lessons/new' component={LessonForm} />
    <Route exact path='/lessons/:id' component={Lesson} />
    <Route exact path='/lessons/:id/edit' component={LessonEdit} />
    <Route exact path='/lessons/:id/tip' component={LessonTip} />
    <Route exact path='/challenges' component={Challenges} />
    <Route exact path='/challenges/:id' component={Challenge} />
    <Route path='/chat' component={Chat} />
    <Route path='/account' component={Account} />
    <Route path='/register' component={RegisterForm} />
    <Route path='/password-reset/request' component={PasswordResetRequest} />
    <Route path='/password-reset/:token' component={PasswordResetForm} />
    <Route path='/signin' component={SignIn} />
    <Route path='/xumm-signin' component={XummSignin} />
    <Route path='/profile/:username' component={Profile} />
    <Route path='/support' component={Support} />
    <Route path='/welcome' component={Welcome} />
    <Route exact path='/blog' component={Blog} />
    <Route exact path='/blog/:slug' component={BlogPost} />
    <Route component={NotFound} />
  </Switch>
);

const AppRoutes = () => (
  <div className='app-layout'>
    <Alert />

    <Header />
    <div className='app-main'>
      <div className='app-scroll-wrapper'>
        <div className='app-scroll-container'>
          <Suspense fallback={<Loader active />}>
            <Routes />
          </Suspense>
        </div>
      </div>
    </div>
  </div>
);

export default AppRoutes;
