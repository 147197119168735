import {
  SET_IMAGE_FILE,
  SET_IMAGE_URL,
  SET_PREVIEW_IMAGE,
  SET_DESCRIPTION,
  SET_STORY,
  SET_IS_GATED,
  SET_GATE_FEE,
  SET_UPLOADING,
  SET_UPLOAD_SUCCESS,
  CLEAR_FORM_STATE,
  LOAD_FORM_STATE
} from './types';

export const postFormInitialState = {
  imageFile: null,
  imageURL: '',
  previewImage: {
    id: '',
    url: 'https://react.semantic-ui.com/images/wireframe/image.png'
  },
  description: '',
  story: '',
  isGated: false,
  gateFee: 0,
  uploading: false,
  uploadSuccess: false,
  postId: '',
  dataLoaded: false
};

const reducer = (state = postFormInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_IMAGE_FILE:
      return {
        ...state,
        imageFile: payload
      };

    case SET_IMAGE_URL:
      return {
        ...state,
        imageURL: payload
      };

    case SET_PREVIEW_IMAGE:
      return {
        ...state,
        previewImage: payload
      };

    case SET_DESCRIPTION:
      return {
        ...state,
        description: payload
      };

    case SET_STORY:
      return {
        ...state,
        story: payload
      };

    case SET_IS_GATED:
      return {
        ...state,
        isGated: payload
      };

    case SET_GATE_FEE:
      return {
        ...state,
        gateFee: payload
      };

    case SET_UPLOADING:
      return {
        ...state,
        uploading: payload
      };

    case SET_UPLOAD_SUCCESS:
      const { postId, uploadSuccess } = payload;
      return {
        ...state,
        postId,
        uploadSuccess,
        uploading: false
      };

    case CLEAR_FORM_STATE:
      return postFormInitialState;

    case LOAD_FORM_STATE:
      const { _id, photoURL, description, story, isGated, gateFee } = payload;
      return {
        ...state,
        previewImage: {
          id: _id,
          url: photoURL
        },
        description,
        story,
        isGated,
        gateFee,
        postId: _id,
        dataLoaded: true
      };

    default:
      return state;
  }
};

export default reducer;
