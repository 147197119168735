import { combineReducers } from 'redux';

import alert from './alert';
import blog from './blog';
import challenge from './challenge';
import chalSubmitForm from './chal-submit-form';
import challenges from './challenges';
import chat from './chat';
import leaderboards from './leaderboards';
import lesson from './lesson';
import lessonForm from './lesson-form';
import lessons from './lessons';
import notifications from './notifications';
import post from './post';
import posts from './posts';
import postForm from './post-form';
import profile from './profile';
import stats from './stats';
import settings from './settings';
import user from './user';

const reducers = {
  alert,
  blog,
  challenge,
  chalSubmitForm,
  challenges,
  chat,
  leaderboards,
  lesson,
  lessonForm,
  lessons,
  notifications,
  post,
  posts,
  postForm,
  profile,
  stats,
  settings,
  user
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
